export type Tabs = 'weekly' | 'monthly' | 'yearly'

export interface DataType {
  label: string
  value: number
}

export type Property = 'weekly' | 'monthly' | 'yearly'

export enum HeaderTabEnum {
  YEAR = 'Yıl',
  MONTH = 'Ay',
  WEEK = 'Hafta',
}
export type HeaderTabType = HeaderTabEnum

export interface YearType {
  date: string
  period: string
  result: [{
    count: number
    monthName: string
  }]
}

export interface MonthType {
  date: string
  period: string
  result: [{
    count: number
    day: number
  }]
}

export interface WeekType {
  endDate: string
  period: string
  startDate: string
  result: [{
    dayName: {
      date: string
      dayName:string
    }
    todos: [{
      date: string
      description: string
      id: number
      isChecked: boolean
      order:number
      title:string
    }]
  }]
}

