import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { LeaveService, EmployeesService, AutomationDepartmentService } from 'common/services'
import { LeaveType } from 'common/services/lib/types/leave'
import { Department, employee } from 'common/services/lib/types/employees'
import { useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from './index'
import moment from 'moment/moment'
import type { Moment } from 'moment'
import { imageAddresser } from '../../../../../../common/helpers/image.helper'

export const useLeave = () => {
  const { setLoader } = useLoader()

  //edit states
  const modalInitialState: Lib.T.ModalPropsType = {
    kind: '',
    title: '',
    editProps: {
      id: 0,
      employeeId: undefined,
      typeLeaveSelected: undefined,
      startDate: '',
      endDate: '',
      dayCount: undefined,
      description: '',
      isHourly: false,
    },
  }
  const [showEditModal, setShowEditModal] = useState(false)
  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ name: '', departmentId: undefined, date: '' })
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['', 'Ad', 'İzin türü', 'Başlangıç zamanı', 'Bitiş zamanı', 'Süre', 'Durum', 'Departman', '']
  const tableSizes = ['3%', 'unset', 'unset', 'unset', 'unset', 'unset', 'unset', 'unset', 'unset']
  const [employeeId, setEmployeeId] = useState<number>()
  const [modalProps, setModalProps] = useState<Lib.T.ModalPropsType>(modalInitialState)
  const [employees, setEmployees] = useState<Lib.T.Employee>([])
  const [typeLeave, setTypeLeave] = useState([{ id: 0, title: '', isHourly: false }])
  const [typeLeaveSelected, setTypeLeaveSelected] = useState<number>()
  const [isHourly, setIsHourly] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [dayCount, setDayCount] = useState<number>()
  const [description, setDescription] = useState('')
  const [date, setDate] = useState<string | undefined | Moment>('')
  const [startHour, setStartHour] = useState<string | undefined | Moment>('')
  const [endHour, setEndHour] = useState<string | undefined | Moment>('')
  const [departments, setDepartments] = useState([{ id: 0, title: '' }])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteReason, setDeleteReason] = useState('')
  const [leaveIsEmpty, setLeaveIsEmpty] = useState(false)

  const tableIdIndex = 10

  const handleCancelEditModal = () => {
    setModalProps(modalInitialState)
    setShowEditModal(prev => !prev)
    setLeaveIsEmpty(false)
  }

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
    setDeleteReason('')
  }, [])

  const getLeaveType = async () => {
    const service = new LeaveService()
    setLoader(true)
    const { success, leaveTypes } = await service.getLeaveTypeList()
    if (!success || !leaveTypes) {
      return
    }
    setTypeLeave(() => {
      return leaveTypes.map((item: LeaveType) => ({
        id: item.leaveType_id,
        title: item.leaveType_type,
        isHourly: item.leaveType_isHourly,
      }))
    })
    setLoader(false)
  }

  const getEmployee = async () => {
    const service = new EmployeesService()
    setLoader(true)
    const { success, employees } = await service.getList({
      limit: 1000,
    })
    if (!success || !employees) {
      return
    }
    setEmployees(() => {
      return employees.map((item: employee) => ({
        id: item.id,
        title: item.firstName ? item.firstName + ' ' + item.lastName : '',
      }))
    })
    setLoader(false)
  }

  const getDepartments = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { success, data } = await service.getList('?isMain=true')
    setLoader(false)
    if (success && data) {
      setDepartments(() => {
        return data.map((item: Department) => ({
          id: item.id,
          title: item.name,
        }))
      })
    }
  }

  const getLeaves = async () => {
    const service = new LeaveService()
    setLoader(true)
    const { success, leaves } = await service.getList({
      name: query.name,
      departmentId: query.departmentId,
      start: query.date,
    })
    setLoader(false)
    if (success && leaves) {
      setTableData(prevData => [
        ...leaves.map(leave => {
          const duration = leave.type.isHourly && moment.duration(moment(leave.end).diff(moment(leave.start)))
          const hours = duration ? Math.floor(duration.asHours()) : 0
          const minutes = duration ? duration.minutes() : 0
          const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
          const hourAndMinute = `${hours}:${minutesString}`

          return [
            <Lib.C.TableImage
              src={leave.leaveFor.avatar ? imageAddresser(leave.leaveFor.avatar.imageThumbnail, true) : '/images/png/avatar.png'}
            />,
            <div>{leave?.leaveFor?.firstName ? leave?.leaveFor.firstName + ' ' + leave?.leaveFor.lastName : ''}</div>,
            <div>{leave.type.type}</div>,
            <div>{leave.type.isHourly ? moment(leave.start).format('DD/MM/YYYY - HH:mm') : moment(leave.start).format('DD/MM/YYYY')}</div>,
            <div>{leave.type.isHourly ? moment(leave.end).format('DD/MM/YYYY - HH:mm') : moment(leave.end).format('DD/MM/YYYY')}</div>,
            <div>{leave.dayCount === 0 ? hourAndMinute : leave.dayCount + ' gün'}</div>,
            <Lib.S.tableStatus type={leave.status}>
              {leave.status === 'approved' ? 'Onaylandı' : leave.status === 'deleted' ? 'Silindi' : leave.status}
            </Lib.S.tableStatus>,
            <div>{leave.leaveFor.user?.userToRoles[0].department.name}</div>,
            <Lib.S.tableButtons>
              {leave.status !== 'deleted' && (
                <Lib.C.TableButton
                  icon="trash_bin"
                  callback={() => {
                    setShowDeleteModal(true)
                    setModalProps({ ...modalInitialState, kind: 'delete', editProps: { id: leave.id } })
                  }}
                />
              )}
            </Lib.S.tableButtons>,
            leave.id,
          ]
        }),
      ])
    }
  }

  const DeleteLeave = useCallback(
    async (id: number) => {
      const service = new LeaveService()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id, deleteReason)

        if (success) {
          setDeleteReason('')
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowEditModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to Delete Leave : ', error)
      } finally {
        setLoader(false)
      }
    },
    [deleteReason, modalProps],
  )

  const EditOrCreateUser = async () => {
    const service = new LeaveService()

    const start = moment(startHour).format('HH:mm')
    const end = moment(endHour).format('HH:mm')
    const dateForHour = moment(date).format('YYYY-MM-DD')

    const isAfterHour = moment(moment(dateForHour + ' ' + start).format()).isAfter(moment(dateForHour + ' ' + end).format())
    const isAfterDay = moment(startDate).isAfter(endDate)

    if (isHourly) {
      if (!Boolean(date) || !Boolean(startHour) || !Boolean(endHour)) {
        swal({
          text: 'lütfen tüm alanı doldurun.',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }

      if (isAfterHour) {
        swal({
          text: 'Başlangıç ​​tarihi bitiş tarihinden büyük olamaz',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }
    } else {
      if (!Boolean(startDate) || !Boolean(endDate) || !Boolean(dayCount)) {
        swal({
          text: 'lütfen tüm alanı doldurun.',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }

      if (isAfterDay) {
        swal({
          text: 'Başlangıç ​​tarihi bitiş tarihinden büyük olamaz',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }
    }

    if (modalProps.kind === 'edit') {
      // edit
      setLoader(true)
      const args = {
        leaveForId: employeeId ? employeeId : 0,
        typeId: typeLeaveSelected ? typeLeaveSelected : 0,
        start: isHourly ? moment(dateForHour + ' ' + start).format() : startDate,
        end: isHourly ? moment(dateForHour + ' ' + end).format() : endDate,
        dayCount: isHourly ? 0 : dayCount ? dayCount : 0,
        description: description,
      }
      const { success } = await service.createOrEdit(args, modalProps.editProps.id)

      if (success) {
        swal({
          className: 'rounded-swal',
          icon: 'success',
          timer: 700,
          buttons: [false],
        }).then(() => {
          setShowEditModal(false)
        })
        setLoader(false)
      }
    } else {
      //create
      setLoader(true)
      const args = {
        leaveForId: employeeId ? employeeId : 0,
        typeId: typeLeaveSelected ? typeLeaveSelected : 0,
        start: isHourly ? moment(date + ' ' + start).format() : startDate,
        end: isHourly ? moment(date + ' ' + end).format() : endDate,
        dayCount: isHourly ? 0 : dayCount ? dayCount : 0,
        description: description,
      }
      const { success: createSuccess } = await service.createOrEdit(args)
      if (createSuccess) {
        swal({
          className: 'rounded-swal',
          icon: 'success',
          timer: 700,
          buttons: [false],
        }).then(() => {
          setShowEditModal(false)
        })
        setLoader(false)
      }
    }

    setFetchAgain(perval => !perval)
    setLoader(false)
  }

  useEffect(() => {
    setEmployeeId(modalProps.editProps.employeeId)
    setTypeLeaveSelected(modalProps.editProps.typeLeaveSelected)
    setStartDate(modalProps.editProps.startDate ? modalProps.editProps.startDate : '')
    setEndDate(modalProps.editProps.endDate ? modalProps.editProps.endDate : '')
    setDayCount(modalProps.editProps.dayCount)
    setDescription(modalProps.editProps.description ? modalProps.editProps.description : '')
    setIsHourly(modalProps.editProps.isHourly ? modalProps.editProps.isHourly : false)
    setStartHour(modalProps.editProps.startDate ? moment(modalProps.editProps.startDate) : undefined)
    setEndHour(modalProps.editProps.endDate ? moment(modalProps.editProps.endDate) : undefined)
    setDate(modalProps.editProps.startDate)
  }, [modalProps])

  useEffect(() => setTableData([]), [fetchAgain])
  useEffect(() => {
    ;(async () => {
      await getEmployee()
      await getLeaveType()
      await getDepartments()
    })()
  }, [])

  useAsyncEffect(getLeaves, [fetchAgain])

  return {
    val: {
      showEditModal,
      description,
      modalProps,
      modalInitialState,
      employeeId,
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
      typeLeaveSelected,
      isHourly,
      startDate,
      endDate,
      dayCount,
      date,
      startHour,
      endHour,
      showDeleteModal,
      deleteReason,
      leaveIsEmpty,
    },
    set: {
      setDescription,
      setModalProps,
      setEmployeeId,
      setQuery,
      setFetchAgain,
      setTypeLeave,
      setTypeLeaveSelected,
      setIsHourly,
      setStartDate,
      setEndDate,
      setDayCount,
      setDate,
      setStartHour,
      setEndHour,
      setShowDeleteModal,
      setDeleteReason,
    },
    on: { handleCancelEditModal, EditOrCreateUser, DeleteLeave, handleDeleteModalVisibility },
    get: {
      query,
      employees,
      typeLeave,
      departments,
    },
  }
}
