import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationNeighbourhoodService } from 'common/services'
import { useEffect, useState, useCallback } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import { isBoolean } from 'lodash'

const modalInitialState: Lib.T.ModalPropsType = {
  kind: 'post',
  name: '',
  editProps: {
    id: 0,
  },
}

export const useTable = () => {
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [fetchAgain, setFetchAgain] = useState<boolean>(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const [neighbourhood, setNeighbourhood] = useState('')
  const [modalProps, setModalProps] = useState<Lib.T.ModalPropsType>(modalInitialState)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const { setLoader } = useLoader()

  const tableIdIndex = 6
  const tableColumns = ['İlçe']
  const tableSizes = ['100%', 'unset']

  const handleModalVisibility = useCallback(() => {
    setShowEditModal(prev => !prev)
  }, [])

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleCancelModal = useCallback(() => {
    setModalProps(modalInitialState)
    handleModalVisibility()
    setNeighbourhood('')
    setIsEmpty(false)
  }, [handleModalVisibility])

  const openEditModal = (id: number, name: string) => {
    setModalProps({
      ...modalInitialState,
      kind: 'edit',
      editProps: { id },
    })
    setNeighbourhood(name)
    handleModalVisibility()
  }

  const fetchAllRoles = useCallback(
    async (reload?: boolean) => {
      const service = new AutomationNeighbourhoodService()
      setLoader(true)
      try {
        const { neighbourhoods, total, success } = await service.getList({
          limit: paginate.limit,
        })
        if (neighbourhoods && success) {
          setTotalData(parseInt(total + ''))
          setTableData(prevData => [
            ...(reload ? [] : prevData),
            ...neighbourhoods.map(i => [
              <div key={i.neighbourhood_id} onClick={() => openEditModal(i.neighbourhood_id, i.neighbourhood_name)}>
                {i.neighbourhood_name}
              </div>,
              <Lib.S.tableButtons>
                <Lib.C.TableButton
                  icon="trash_bin"
                  callback={() => {
                    setShowDeleteModal(true)
                    setModalProps({ ...modalInitialState, kind: 'delete', editProps: { id: i.neighbourhood_id } })
                  }}
                />
                <Lib.C.TableButton icon="edit_outline" callback={() => openEditModal(i.neighbourhood_id, i.neighbourhood_name)} />
              </Lib.S.tableButtons>,
            ]),
          ])
        }
      } catch (error) {
        console.error('Failed to fetch roles:', error)
      } finally {
        setLoader(false)
      }
    },
    [handleModalVisibility],
  )

  const EditOrCreateNeighbourhood = useCallback(async () => {
    const service = new AutomationNeighbourhoodService()
    if (neighbourhood.length < 1) {
      swal({
        text: 'lütfen tüm alanı doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return
    }
    setIsEmpty(false)
    setLoader(true)
    try {
      const args = { name: neighbourhood }
      const success = modalProps.kind === 'edit' ? await service.edit(args, modalProps.editProps.id) : await service.create(args)

      if (success) {
        swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
          setShowEditModal(false)
        })
        setFetchAgain(prev => !prev)
        setModalProps(modalInitialState)
        setNeighbourhood('')
      }
    } catch (error) {
      console.error('Failed to edit/create neighbourhood: ', error)
    } finally {
      setLoader(false)
    }
  }, [neighbourhood, modalProps])

  const DeleteNeighbourhood = useCallback(
    async (id: number) => {
      const service = new AutomationNeighbourhoodService()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id)

        if (isBoolean(success)) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowEditModal(false)
          })
          setFetchAgain(prev => !prev)
        } else {
          swal({
            text: 'Bu mahalle diğer işçiler tarafından kullanılıyor!',
            icon: 'error',
          })
        }
      } catch (error) {
        console.error('Failed to edit/create neighbourhood: ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const handleShowMore = useCallback(() => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }, [paginate, totalData])

  useEffect(() => setTableData([]), [fetchAgain])
  useAsyncEffect(fetchAllRoles, [paginate, fetchAgain])

  return {
    val: { neighbourhood, modalProps, showEditModal, modalInitialState, showDeleteModal, isEmpty },
    get: { tableData, totalData, paginate, fetchAgain, tableIdIndex, tableColumns, tableSizes },
    set: { setFetchAgain, setNeighbourhood, setShowEditModal, setModalProps, setShowDeleteModal },
    on: {
      handleShowMore,
      handleCancelModal,
      EditOrCreateNeighbourhood,
      handleModalVisibility,
      DeleteNeighbourhood,
      handleDeleteModalVisibility,
    },
  }
}
