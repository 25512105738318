import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useOvertime } from './lib/hooks'
import { Sticky } from 'common/components/ui-kit/sticky'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Table } from 'common/components/ui-kit/table-2'
import { DatePicker } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/tr_TR'
import React from 'react'

function Overtime() {
  const { val, set, on, get } = useOvertime()

  return (
    <PanelWrapper tab={['automation', '/automation/overtime']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Mesai</p>
          <div>
            <div className="inputContainer" style={{maxWidth: 300}}>
              <Input
                type="search"
                placeholder="Ad ile Ara..."
                lessBorder
                value={get.query.name}
                onInput={value => set.setQuery({ ...get.query, name: value.currentTarget.value })}
                onEnter={() => set.setFetchAgain(perv => !perv)}
                containerStyles={{height: 40}}
                inputStyles={{height: 40}}
              />
            </div>
            <Button
              className="buttonContainer"
              icon="search"
              mood="accent"
              callback={() => set.setFetchAgain(perv => !perv)}
              iconProps={{ color: 'white', size: 20 }}
              containerStyles={{height: 40}}
            />
          </div>

          <DropDown2
            className="dropdown"
            options={val.departmentIds}
            label=""
            height="40px"
            value={get.query.departmentId}
            placeholder="Departman Seç"
            onChange={value => {
              set.setQuery({ ...get.query, departmentId: value })
              set.setFetchAgain(perv => !perv)
            }}
            containerStyles={{maxWidth: 150}}
          />

          <DropDown2
            className="dropdown"
            options={get.serviceIds}
            label=""
            height="40px"
            value={get.query.serviceId}
            placeholder="Servis Seç"
            onChange={value => {
              set.setQuery({ ...get.query, serviceId: value })
              set.setFetchAgain(perv => !perv)
            }}
            containerStyles={{maxWidth: 150}}
          />

          <div style={{ maxWidth: 150 }}>
            <DatePicker
              value={get.query.date ? moment(get.query.date, 'YYYY-MM-DD') : null}
              onChange={(date: moment.Moment | null) => {
                if (date) {
                  const formattedDateForServer = date.format('YYYY-MM-DD')
                  set.setQuery({ ...get.query, date: formattedDateForServer })
                  set.setFetchAgain(prev => !prev)
                } else {
                  set.setQuery({ ...get.query })
                  set.setFetchAgain(prev => !prev)
                }
              }}
              size="small"
              locale={locale}
              allowClear={false}
              className="w-100"
              style={{ height: 40, borderRadius: 7 }}
              inputReadOnly
              format="DD-MM-YYYY"
            />
          </div>

          <div className="d-flex justify-content-start">
            <Button
              mood="accent"
              callback={() => {
                set.setQuery({ departmentId: undefined, serviceId: undefined, name: '', date: '' })
                set.setFetchAgain(perv => !perv)
              }}
              text="Filtreyi Temizle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 130 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button
              mood="accent"
              callback={() => on.handleServicePopup()}
              text="Servis listesi"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
            <Button
              mood="accent"
              callback={() => on.handleDepartmentsPopup()}
              text="Departman listesi"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                totalData={get.totalData}
                paginate={get.paginate}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* Departman modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showDepartmentModal}
          onCancel={on.handleCancelDepartmentModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Departman</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelDepartmentModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="px-3">
              <Lib.S.content>
                <div className="tableContainer">
                  <Table
                    columns={val.departmentTableColumns}
                    columnSizes={val.departmentTableSizes}
                    data={val.departmentTableData}
                    //onLoadMore={on.handleShowMore}
                    //totalData={get.totalData}
                    //paginate={get.paginate}
                    disabledIndexes={[val.departmentTableIdIndex, val.departmentTableIdIndex - 1]}
                    contentStyle={{background: '#fff'}}
                  />
                </div>
              </Lib.S.content>
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Departman modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showServiceModal}
          onCancel={on.handleCancelServiceModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Servis</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelServiceModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="px-3">
              <Lib.S.content>
                <div className="tableContainer">
                  <Table
                    columns={val.serviceTableColumns}
                    columnSizes={val.serviceTableSizes}
                    data={val.serviceTableData}
                    //onLoadMore={on.handleShowMore}
                    //totalData={get.totalData}
                    //paginate={get.paginate}
                    disabledIndexes={[val.serviceTableIdIndex, val.serviceTableIdIndex - 1]}
                    contentStyle={{background: '#fff'}}
                  />
                </div>
              </Lib.S.content>
            </div>
          </Lib.S.ModalContainer>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default Overtime
