import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class PieceService extends BaseService{
  endpoint = 'pieces/'

  constructor() {
    super();
  }

  async create(args: Lib.T.Pieces.CreateOrEditArgs): Promise<Lib.T.Pieces.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 201 || !data.piece) {
        return { success: false }
      }

      return { success: true, piece: data.piece }
    }, config);
  }

  async edit(args: Lib.T.Pieces.CreateOrEditArgs, id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return false
      }

      return true
    }, config);
  }

  async getList(args: Lib.T.Pieces.GetListArgs): Promise<Lib.T.Pieces.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(DOM.objectToSearch(args)),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200) {
        return { success: false }
      }

      return {
        success: true,
        pieces: {
          total: data.total,
          list: data.pieces,
        },
      }
    }, config);
  }

  async clone(id: number): Promise<Lib.T.Pieces.CloneResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id + '/getVariant'),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status === 201) {
        return { success: true, data: data.piece }
      }
      return { success: false }
    }, config);
  }

  async findOne(id: number): Promise<Lib.T.Pieces.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.piece) {
        return { success: false }
      }

      return {
        success: true,
        piece: data.piece,
      }
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status === 200) {
        return true
      }
      return false
    }, config);
  }
}
