import { ModalProps } from 'antd'
import swal from 'sweetalert'
import { ImageDetail } from 'common/components/ui-kit/image-list-slider/lib/typing'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useDebounce } from 'common/hooks/use-debounce'
import { useLoader } from 'common/hooks/use-loader'
import { OrdersService } from 'common/services'
import { ProductProblems } from 'common/services/lib/types/orders'
import _ from 'lodash'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Lib from '.'
import { handleUnapprovedProblems } from 'common/store/actions/global.action'
import { RootState } from 'common/store/root-reducer'
import InfiniteScroll from 'react-infinite-scroll-component'

export const useProductErrorTable = () => {
  const { setLoader, loader } = useLoader()
  const [tabs, setTabs] = useState<Lib.T.TabsEnum>(Lib.T.TabsEnum.CURRENT_ERRORS_TAB)
  const [products, setProducts] = useState<ProductProblems[] | []>([])
  const isInitialRender = useRef(true)
  const isTabStateChanged = useRef(false)
  const { unApprovedProblems } = useSelector((state: RootState) => state.globals)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [detailModalState, setDetailModalState] = useState<ProductProblems>()
  const [imageForDescriptionModal, setImageForDescriptionModal] = useState('')
  const [showImageModal, setShowImageModal] = useState(false)
  const [showDescriptionImageModal, setShowDescriptionImageModal] = useState(false)
  const [description, setDescription] = useState('')
  const [productCode, setProductCode] = useState('')
  const [descriptionModal, setDescriptionModal] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState(0)
  const [tableLoader, setTableLoader] = useState(false)
  const [changeWeights, setChangeWeights] = useState<boolean>()

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 100)
  const [page, setPage] = useState(1)
  const [limit] = useState(50)
  const [totalData, setTotalData] = useState(0)
  const infiniteScrollRef = useRef<InfiniteScroll | null>(null)

  const [approveReasonModal, setApproveReasonModal] = useState(false)
  const [approvalModalState, setApprovalModalState] = useState<{
    reason: string | undefined
    createdAt: string | undefined
    approvedAt: string | undefined
  }>()

  const [imageActiveIndex, setImageActiveIndex] = useState(0)
  const [imageSliderImageDetail, setImageSliderImageDetail] = useState<ImageDetail[]>([])
  // product detail modal states
  const [showProductDetailModal, setShowProductDetailModal] = useState(false)
  const [productId, setProductId] = useState(0)

  const dispatch = useDispatch()

  const fetchProductProblems = async () => {
    const service = new OrdersService()

    if (isInitialRender.current || isTabStateChanged.current) {
      setLoader(true)
    } else {
      setTableLoader(true)
    }
    const { success, problems, total } =
      tabs === Lib.T.TabsEnum.HISTORY_ERRORS_TAB
        ? await service.historyProductProblems({ limit, page, keyword: debouncedSearchTerm as string })
        : await service.productProblems({ limit, page, keyword: debouncedSearchTerm as string })

    if (success) {
      const removeWordDepartment = problems?.map(problem => {
        return { ...problem, department: problem.department?.split(' ')[0] }
      })

      if (searchTerm && removeWordDepartment?.length) {
        setProducts(removeWordDepartment)
      }

      if (page === 1 && removeWordDepartment?.length) {
        setProducts(removeWordDepartment)
      } else if (!removeWordDepartment?.length) {
        setProducts([])
      } else {
        setProducts(perv => [...perv, ...removeWordDepartment!])
      }

      setTotalData(total)

      const dataForImageModal: ImageDetail[] = removeWordDepartment?.map(prod => ({
        image: prod.productImage as string,
        productImageThumbnail: prod.productImageThumbnail as string,
        productImageLink: prod.productImageLink as string,
        productCode: prod.productCode,
        weight: '0',
        chainImage: prod.productSubCategoryTypeImage,
        lockImage: prod.lockImage,
      }))!

      setImageSliderImageDetail(dataForImageModal)

      setLoader(false)

      setTableLoader(false)
    }

    setLoader(false)

    setTableLoader(false)

    isTabStateChanged.current = false
  }

  const approveProductProblem = async (id: number) => {
    if (description.length < 6) {
      return swal({
        text: 'Metniniz 6 karakterden fazla olmalı.',
        icon: 'error',
        dangerMode: true,
      })
    }
    if (changeWeights === undefined) {
      return swal({
        text: 'Lütfen ürünün ağırlık durumunu seçin.',
        icon: 'error',
        dangerMode: true,
      })
    }
    const service = new OrdersService()
    setLoader(true)
    const success = await service.approveProductProblem(description, id, changeWeights)

    if (success) {
      const filteredProducts = products?.filter(prod => prod.id !== id)
      dispatch(handleUnapprovedProblems(unApprovedProblems - 1))

      setProducts(filteredProducts)

      setDescriptionModal(false)
      setDescription('')
      setLoader(false)
    }
    setLoader(false)
  }

  const handleChangeTabs = (tab: Lib.T.TabsEnum) => {
    setSearchTerm('')

    setTabs(tab)
    isTabStateChanged.current = true
    setPage(1)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (page >= 2) {
      setPage(1)
    }
    const { value } = e.target
    setSearchTerm(value)
  }

  const toggleModalHandler = (problemId: number) => {
    if (products?.length) {
      const targetedProduct = products.filter(prod => prod.id === problemId)[0]

      if (!_.isEmpty(targetedProduct)) {
        setDetailModalState(targetedProduct)
        setShowDetailsModal(true)
      }
    }
  }

  const handleShowMore = () => {
    isTabStateChanged.current = true
    setPage(perv => perv + 1)
  }

  const toggleDescriptionModal = () => {
    setDescriptionModal(perv => !perv)
  }

  const toggleApproveReasonModal = () => {
    setApproveReasonModal(perv => !perv)
  }

  const detailsModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showDetailsModal,
    width: '70%',
    afterClose: () => {},
    onCancel: () => setShowProductDetailModal(false),
    className: 'noHeaderModal',
  }

  const productDetailModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showProductDetailModal,
    onCancel: () => setShowDetailsModal(false),
    width: 1200,
    style: { height: 700, top: 20 },
  }

  const imageModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showImageModal,
    width: '50%',
    style: { top: '20px', height: '90vh' },
    bodyStyle: { padding: 0 },
    onCancel: () => setShowImageModal(false),
    className: 'noHeaderModal scrollWidthModal',
  }

  const descriptionModalProps: ModalProps = {
    footer: null,
    title: 'Ürün kodu: ' + productCode,
    visible: descriptionModal,
    onCancel: () => {
      toggleDescriptionModal()
      setChangeWeights(undefined)
      setDescription('')
    },
    afterClose: () => setChangeWeights(undefined)
  }

  const approveReasonModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: approveReasonModal,
    className: 'noHeaderModal',
  }
  const handleOpenImageModal = (index: number) => {
    setShowImageModal(true)
    setImageActiveIndex(index)
  }

  useAsyncEffect(fetchProductProblems, [page, tabs, debouncedSearchTerm])
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    }
  }, [])

  return {
    get: {
      products,
      detailsModalProps,
      detailModalState,
      imageForDescriptionModal,
      showImageModal,
      productId,
      imageModalProps,
      imageActiveIndex,
      imageSliderImageDetail,
      showDescriptionImageModal,
      descriptionModalProps,
      description,
      selectedProductId,
      tabs,
      loader,
      approveReasonModalProps,
      approvalModalState,
      searchTerm,
      page,
      limit,
      totalData,
      infiniteScrollRef,
      tableLoader,
      productCode,
      changeWeights
    },
    set: {
      setShowDetailsModal,
      setImageForDescriptionModal,
      setShowImageModal,
      setProductId,
      setShowDescriptionImageModal,
      setDescription,
      setSelectedProductId,
      setTabs,
      setApprovalModalState,
      setProductCode,
      setChangeWeights
    },
    on: {
      approveProductProblem,
      toggleModalHandler,
      handleOpenImageModal,
      toggleDescriptionModal,
      handleChangeTabs,
      handleSearch,
      toggleApproveReasonModal,
      handleShowMore,
    },
    modal: {
      productModal: {
        props: productDetailModalProps,
        toggle: () => setShowProductDetailModal(!showProductDetailModal),
      },
    },
  }
}
