import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationServiceIdsService } from 'common/services'
import { CreateServiceIdArgs } from 'common/services/lib/types/automationServiceId'
import { useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'

export const useServiceIds = () => {
  const { setLoader } = useLoader()

  //edit states
  const modalInitialState: Lib.T.ModalPropsType = {
    kind: 'post',
    id: 0,
    editProps: {
      plate: '',
      code: '',
      name: '',
      driverName: '',
      driverPhoneNumber: '',
      description: '',
      capacity: 0,
    },
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ name: '', departmentId: undefined, roleId: undefined })
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['Kod', 'İsim', 'Sürücü Adı', 'Araç Plakası', 'Telefon', 'Kapasite', '']
  const tableSizes = ['3%', 'unset', 'unset', 'unset', 'unset', '7%']
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [modalProps, setModalProps] = useState<Lib.T.ModalPropsType>(modalInitialState)
  const [driverName, setDriverName] = useState('')
  const [driverPhoneNumber, setDriverPhoneNumber] = useState('')
  const [plate, setPlate] = useState('')
  const [capacity, setCapacity] = useState<number | string>(0)
  const [code, setCode] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchedServices, setSearchedServices] = useState<Lib.T.Service[]>([])
  const [servicesList, setServicesList] = useState<Lib.T.Service[]>([])
  const [searchFlag, setSearchFlag] = useState(false)
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [isFieldEmpty, setIsFieldEmpty] = useState(false)

  const tableIdIndex = 8

  const handleCancelEditModal = () => {
    setModalProps(modalInitialState)
    setShowEditModal(prev => !prev)
    setName('')
    setDescription('')
    setCode('')
    setPlate('')
    setCapacity(0)
    setDriverName('')
    setDriverPhoneNumber('')
    setIsFieldEmpty(false)
  }

  const handleModalVisibility = useCallback(() => {
    setShowEditModal(prev => !prev)
  }, [])

  const openEditModal = async (id: number) => {
    const serviceGetOne = new AutomationServiceIdsService()
    setLoader(true)
    const { success, serviceItem } = await serviceGetOne.getOne(id)
    setLoader(false)
    if (success && serviceItem) {
      setModalProps({
        ...modalInitialState,
        kind: 'edit',
        id: serviceItem?.id,
        editProps: {
          plate: serviceItem?.plate,
          code: serviceItem?.code,
          name: serviceItem?.name,
          driverName: serviceItem?.driverName,
          driverPhoneNumber: serviceItem?.driverPhoneNumber,
          description: serviceItem?.description,
          capacity: serviceItem?.capacity,
        },
      })
      handleModalVisibility()
    }
  }

  const getServicesByGroup = async () => {
    const service = new AutomationServiceIdsService()
    setLoader(true)
    const { success, services, total } = await service.getList({
      limit: paginate.limit,
    })
    setLoader(false)
    if (success && services && !searchFlag) {
      setServicesList(services)
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...services.map(service => {
          return [
            <div>{service?.code}</div>,
            <div onClick={() => openEditModal(service.id)} style={{ cursor: 'pointer' }}>
              {service?.name}
            </div>,
            <div>{service?.driverName}</div>,
            <div>{service?.plate}</div>,
            <div>{service?.driverPhoneNumber}</div>,
            <div>{service?.capacity}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitialState, kind: 'delete', id: service.id })
                }}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => openEditModal(service.id)} />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    } else if (searchFlag) {
      setTableData(prevData => [
        ...searchedServices.map(service => {
          return [
            <div>{service?.code}</div>,
            <div onClick={() => openEditModal(service.id)} style={{ cursor: 'pointer' }}>
              {service?.name}
            </div>,
            <div>{service?.driverName}</div>,
            <div>{service?.plate}</div>,
            <div>{service?.driverPhoneNumber}</div>,
            <div>{service?.capacity}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitialState, kind: 'delete', id: service.id })
                }}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => openEditModal(service.id)} />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  function isEmpty(value: string | number | null | undefined): boolean {
    return value === null || value === undefined || value === ''
  }

  function validateArgs(obj: CreateServiceIdArgs): boolean {
    for (const key in obj) {
      if (key === 'description') {
        continue
      }

      if (isEmpty(obj[key as keyof CreateServiceIdArgs])) {
        return false
      }
    }
    return true
  }

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const CreateOrEditServiceId = useCallback(async () => {
    const service = new AutomationServiceIdsService()
    setLoader(true)

    const args: CreateServiceIdArgs = {
      plate: plate || modalProps.editProps.plate,
      code: code || modalProps.editProps.code,
      name: name,
      driverName: driverName || modalProps.editProps.driverName,
      driverPhoneNumber: driverPhoneNumber || modalProps.editProps.driverPhoneNumber,
      description: description,
      capacity: Number(capacity) || modalProps.editProps.capacity,
    }

    const allFieldsFilled = validateArgs(args)

    if (!allFieldsFilled) {
      swal({
        text: 'lütfen tüm alanı doldurun.',
        icon: 'error',
      })
      setLoader(false)
      setIsFieldEmpty(true)
      return
    }

    setIsFieldEmpty(false)
    let success = false

    if (modalProps.kind === 'edit') {
      const result = await service.edit(args, modalProps.id)
      success = result.success
    } else {
      success = await service.create(args)
    }

    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        setName('')
        setDescription('')
        setModalProps(modalInitialState)
        setShowEditModal(false)
      })
      setFetchAgain(prev => !prev)
    }

    setLoader(false)
  }, [modalProps, name, description, plate, code, driverName, driverPhoneNumber, capacity])

  const DeleteServiceId = useCallback(
    async (id: number) => {
      const service = new AutomationServiceIdsService()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id)

        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowEditModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to edit/create neighbourhood: ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  useEffect(() => {
    if (searchQuery.length > 0) {
      setSearchFlag(true)
      setSearchedServices(servicesList.filter(service => service.name.toLowerCase().includes(searchQuery.toLowerCase())))
      return
    }

    setSearchFlag(false)
    setSearchQuery('')
  }, [searchQuery, servicesList, setLoader])

  useEffect(() => {
    setDriverName(modalProps.editProps.driverName ?? '')
    setDriverPhoneNumber(modalProps.editProps.driverPhoneNumber ?? '')
    setCapacity(modalProps.editProps.capacity ?? '')
    setPlate(modalProps.editProps.plate ?? '')
    setCode(modalProps.editProps.code ?? '')
    setName(modalProps.editProps.name ?? '')
    setDescription(modalProps.editProps.description ?? '')
  }, [modalProps])

  useEffect(() => setTableData([]), [fetchAgain])

  useAsyncEffect(getServicesByGroup, [paginate, fetchAgain])

  return {
    val: {
      showEditModal,
      name,
      description,
      modalProps,
      modalInitialState,
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
      driverName,
      driverPhoneNumber,
      plate,
      capacity,
      code,
      showDeleteModal,
      searchQuery,
      isFieldEmpty,
    },
    set: {
      setName,
      setDescription,
      setModalProps,
      setQuery,
      setFetchAgain,
      setDriverName,
      setDriverPhoneNumber,
      setPlate,
      setCapacity,
      setCode,
      setShowDeleteModal,
      setSearchQuery,
    },
    on: {
      handleCancelEditModal,
      DeleteServiceId,
      handleDeleteModalVisibility,
      CreateOrEditServiceId,
      handleShowMore,
    },
    get: {
      query,
      paginate,
      totalData,
    },
  }
}
