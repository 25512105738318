import { BaseService } from './BaseService';
import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class DepartmentService extends BaseService {
  endpoint = 'department';

  constructor() {
    super();
  }

  async getList(isMain: string): Promise<Lib.T.Department.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(isMain),
      headers: { authorization: this.accessToken },
    };
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200 || !data.departments) {
        return { success: false, total: 0 }
      }
      return { success: true, total: data.total, data: data.departments }
    }, config);
  }

  async create(args: Lib.T.Department.DepartmentArgs): Promise<Lib.T.Department.ListResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }
    return this.apiCallWithRefresh(async () => {
      const { status, data } = await ApiCaller(config)
      if (status !== 201) {
        return { success: false }
      }

      return {success: true, total: 0}
    }, config);
  }

  async edit(args: Lib.T.Department.DepartmentArgs, id: number): Promise<Lib.T.Department.ListResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }
    return this.apiCallWithRefresh(async () => {
      const { status, data } = await ApiCaller(config)
      if (status !== 200) {
        return { success: false }
      }

      return {success: true, total: 0}
    }, config);
  }

  async delete(id: number): Promise<Lib.T.Department.ListResult> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const { status, data } = await ApiCaller(config)

      if (status !== 200) {
        return { success: false }
      }

        return {success: true, total: 0}
    }, config);
  }

  async findOne(id: number): Promise<any> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const { status, data } = await ApiCaller(config)

      if (status === 200) {
        return {
          success: true,
          department: data,
        }
      }
      return {
        success: false,
      }
    }, config);
  }
}
