import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class ProductSubCategory extends BaseService{

  constructor() {
    super();
  }

  async create({ image, name, parent, widths }: Lib.T.ProductSubCategory.CreateArgs): Promise<Lib.T.ProductSubCategory.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('product-sub-categories'),
      headers: { authorization: this.accessToken },
      data: {
        productSubCategory: name,
        productSubCategoryImage: image,
        parent,
        widths,
      },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 201 || !data.productSubCategory) {
        return { success: false }
      }
      return { success: true, data: data.productSubCategory }
    }, config);
  }

  async getList(args?: Lib.T.ProductSubCategory.GetListArgs): Promise<Lib.T.ProductSubCategory.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('product-sub-categories').concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200) {
        return { success: false }
      }

      return { success: true, data: data.productSubCategories }
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`product-sub-categories/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return false
      }

      return status === 200
    }, config);
  }

  async edit(id: number, args: Lib.T.ProductSubCategory.EditArgs): Promise<Lib.T.ProductSubCategory.EditResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`product-sub-categories/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.productSubCategory) {
        return { success: false }
      }

      return { success: true, data: data.productSubCategory }
    }, config);
  }
}
