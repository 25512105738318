import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class ChainsService extends BaseService{
  endPoint = 'chains'

  constructor() {
    super();
  }

  async create({ title, image, stones }: Lib.T.Chains.CreateArgs): Promise<Lib.T.Chains.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint),
      headers: { authorization: this.accessToken },
      data: {
        title,
        image,
        stones,
      },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 201 || !data.chain) {
        return { success: false }
      }

      return { success: true, data: data.chain }
    }, config);
  }

  async getList(args?: Lib.T.Chains.GetListArgs): Promise<Lib.T.Chains.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.chains) {
        return { success: false }
      }

      return { success: true, data: data.chains, total: data.total }
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return false
      }

      return status === 200
    }, config);
  }

  async edit(id: number, args: Lib.T.Chains.EditArgs): Promise<Lib.T.Chains.EditResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.lock) {
        return { success: false }
      }

      return { success: true, data: data.lock }
    }, config);
  }

  async findOne(id: number): Promise<Lib.T.Chains.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.chain) {
        return { success: false }
      }

      return {
        success: true,
        chain: data.chain,
      }
    }, config);
  }
}
