import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Breaker } from 'common/components/ui-kit/breaker'
import { Content } from 'common/components/ui-kit/content'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import { Sticky } from 'common/components/ui-kit/sticky'
import { useNewPermissions } from 'common/routes/hooks'
import React, { Fragment } from 'react'
import * as Lib from './lib'

const Automation: React.FC = ({}) => {
  const { list, setKeyword } = Lib.H.useAutomationItems()
  const { secondaryPermission } = useNewPermissions()
  
  return (
    <>
      <PanelWrapper tab={['automation', '/automation/list']} title="Seçenekler">
        <Sticky>
          <Input
            onChange={evt => setKeyword(evt.currentTarget.value || '')}
            placeholder="Ara..."
            required
            icon="search"
            style={{ border: 'none' }}
          />
        </Sticky>

        <Content>
          <Breaker note="Seçenekler" textBack="#f8f8f8" />
          {list.automation &&
            list.automation.map((item, index) => {
              return item.secondaryPermissions?.some(role => secondaryPermission.includes(role)) ? (
                <>
                  <Lib.C.Card {...item} key={index} />
                </>
              ) : (
                <Fragment key={item.title} />
              )
            })}
          <Gap size={20} mood="H" />
        </Content>
      </PanelWrapper>
    </>
  )
}

export default Automation
