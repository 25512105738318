import { RootState } from 'common/store/root-reducer'
import { SavedUser } from 'common/typing/saved-user.interface'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from './../definition/private.routes'

export const usePermissions = () => {
  const { globals } = useSelector((state: RootState) => state)
  const user = <SavedUser>globals.user
  const history = useHistory()

  const checkPath = () => {
    let url = window.location.pathname

    if (!isNaN(parseInt(url.split('/').pop()!))) {
      const splitUrl = url.split('/')
      url = splitUrl.splice(0, splitUrl.length - 1).join('/') + '/:id'
    }

    let hasAccess = false
    const routes = Object.keys(privateRoutes)
    routes.forEach(i => {
      if (privateRoutes[i].get === url) {
        if (privateRoutes[i].permissions.includes(user.group.name)) {
          hasAccess = true
          return
        }
      }
    })

    if (url === '/' || url === '/:id') {
      history.replace('/products/list')
      return
    }

    if (!hasAccess) {
      history.replace('/403')
    }
  }

  //useEffect(checkPath, [])
  return user.group.name
}

export const useNewPermissions = () => {
  const { globals } = useSelector((state: RootState) => state)
  const user = <SavedUser>globals.user
  const history = useHistory()

  const checkPath = () => {
    let url = window.location.pathname

    if (!isNaN(parseInt(url.split('/').pop()!))) {
      const splitUrl = url.split('/')
      url = splitUrl.splice(0, splitUrl.length - 1).join('/') + '/:id'
    }

    //let hasAccess = false
    const userType = user.userType
    const routes = Object.keys(privateRoutes)
    if (userType === 'employee') {
      routes.forEach(i => {
        if (privateRoutes[i].get === url) {
          if (privateRoutes[i].newPermissions?.some(role=> user.permissions.secondaryRoles[0]?.permissions?.includes(role))) {
            //hasAccess = true
            return
          }
        }
      })
    } else {
      routes.forEach(i => {
        if (privateRoutes[i].get === url) {
          if (privateRoutes[i].newPermissions?.some(role=> user.permissions.primaryRole.permissions?.includes(role))) {
            //hasAccess = true
            return
          }
        }
      })
    }
    if (url === '/' || url === '/:id') {
      history.replace('/products/list')
      return
    }

    // if (!hasAccess) {
    //   history.replace('/403')
    //   return
    // }
  }

  useEffect(checkPath, [])
  return {
    permission: user.permissions.primaryRole.permissions,
    secondaryPermission: user.permissions.secondaryRoles[0]?.permissions,
    userType: user.userType
  }
}