import * as Lib from './lib'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { BaseService } from './BaseService'

export class LeaveTypeService extends BaseService{
  endpoint = 'automation/leave-type'

  constructor() {
    super();
  }

  async getList(): Promise<Lib.T.LeaveType.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status === 200) {
        return { success: true, leaveTypes: data.leaveTypes, total: data.total }
      }
      return { success: false, total: 0, leaveTypes: [] }
    }, config);
  }

  async create(args: Lib.T.LeaveType.CreateArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      return status === 201
    }, config);
  }

  async edit(args: Lib.T.LeaveType.EditArgs, id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      return status === 201
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      return status === 201
    }, config);
  }
}
