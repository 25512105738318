import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class GroupService extends BaseService{
  endpoint = 'groups'

  constructor() {
    super();
  }

  async getList(): Promise<Lib.T.Group.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.groups) {
        return { success: false }
      }
      return { success: true, data: data.groups }
    }, config);
  }
}
