import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class OvertimeService extends BaseService{
  endpoint = 'automation/overtime/officer'

  constructor() {
    super();
  }

  async getList(filters?: Lib.T.Overtime.GetListFilters): Promise<Lib.T.Overtime.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200 || !data.overtimes) {
        return { success: false, total: 0 }
      }
      return { success: true, overtimes: data.overtimes, total: data.total }
    }, config);
  }
}
