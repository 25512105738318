import { Gap } from 'common/components/ui-kit/gap'
import { Icon } from 'common/components/ui-kit/icon'
import { GET_UNREAD_NOTIFICATION_FAIL, GET_UNREAD_NOTIFICATION_SUCCESS, GET_UNSEEN_MESSAGES_SUCCESS } from 'common/constants/action-types'
import { appName, sidebarOptions, sidebarSecondaryOptions } from 'common/constants/strings'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useNewPermissions } from 'common/routes/hooks'
import { NotificationsService, OrdersService } from 'common/services'
import { Global } from 'common/store/actions'
import { handleUnapprovedProblems } from 'common/store/actions/global.action'
import { RootState } from 'common/store/root-reducer'
import { FC, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Lib from './lib'
import { MessagingService } from 'common/services/messaging'
import { Permissions, RolesTR } from '../../../../routes/types/roles.type'

export const PanelSidebar: FC<Lib.T.PanelSidebarProps> = ({ activeItem }): JSX.Element => {
  const { permission, secondaryPermission, userType } = useNewPermissions()
  const accessPermission = ['problem-order'].some(role=> permission.includes(role))

  const { sidebar, user, unSeenMessages } = useSelector((s: RootState) => s.globals)
  const dispatch = useDispatch()
  const closeSidebar = () => dispatch(Global.toggleSidebar(false))
  const openSidebar = () => dispatch(Global.toggleSidebar(true))

  const getUnreadNotifications = async () => {
    const service = new NotificationsService()

    try {
      const result = await service.getNotifications()
      dispatch({ type: GET_UNREAD_NOTIFICATION_SUCCESS, payload: result.totalUnread })
    } catch (e) {
      dispatch({ type: GET_UNREAD_NOTIFICATION_FAIL })
    }
  }

  const getUnseenMessages = async () => {
    const service = new MessagingService()

    const { totalCount, success } = await service.getTotalUnseen()
    
    if (success) {
    

      dispatch({ type: GET_UNSEEN_MESSAGES_SUCCESS, payload: totalCount })
    }
  }

  const fetchProductProblems = async () => {
    // if (user.group.name === 'Satış Temsilcisi') return
    // if (user.group.name === 'Reçine Departmanı' || user.group.name === 'Projet Departmanı') return
    // if (history.location.pathname.includes('product-error')) return

    const service = new OrdersService()
    const { success, total } = await service.productProblems()

    if (success) {
      dispatch(handleUnapprovedProblems(total))
    }
  }

  useEffect(() => {
    const productProblemsCount = setInterval(async () => {
      accessPermission && await fetchProductProblems()
      await getUnreadNotifications()
    }, 30000);
    return () => clearInterval(productProblemsCount);
  }, [])

  useEffect(() => {
    ;(async () => {
      accessPermission && await fetchProductProblems()
    })()
  }, [])

  useAsyncEffect(getUnreadNotifications, [])
  useAsyncEffect(getUnseenMessages, [])

  return (
    <>
      <Lib.S.sidebarBackdrop active={sidebar} onClick={() => closeSidebar()} />
      <Lib.S.sidebarContainer active={sidebar} isCollapsed={sidebar}>
        <div className="logo">
          <Link to="/products/list" style={{ marginTop: !sidebar ? '15px' : 0 }}>
            {sidebar ? <Icon name="sozer" size={40} color="#fff" /> : <img src="/images/logo/sidebarLogo.png" alt={appName} />}
          </Link>

          <span className="closeIcon" onClick={() => closeSidebar()}>
            <Icon name="backward_square" color="white" size={18} />
          </span>
        </div>

        <Gap mood="H" size={10} />

        <Lib.S.ScrollSection className="scrollSection">
          {!sidebar && (
            <div className="background">
              <Icon name="sozer" color="rgba(226, 222, 222, 0.041)" />
            </div>
          )}

          {sidebarOptions.map((item, index) => {
            return item?.newPermissions?.some(role=> permission?.includes(role)) ? (
              <>
                <Lib.C.SidebarItem
                  isCollapsed={sidebar}
                  key={item.id}
                  {...item}
                  active={item.id === activeItem[0]}
                  activeSubItem={activeItem[1]}
                  group={permission}
                />
              </>
            ) : (
              <Fragment key={item.id} />
            )
          })}
          {userType === 'employee' && sidebarSecondaryOptions.map((item, index) => {
            return item?.secondaryPermissions?.some(role=> secondaryPermission?.includes(role)) ? (
              <>
                <Lib.C.SidebarItem
                  isCollapsed={sidebar}
                  key={item.id}
                  {...item}
                  active={item.id === activeItem[0]}
                  activeSubItem={activeItem[1]}
                  group={secondaryPermission}
                />
              </>
            ) : (
              <Fragment key={item.id} />
            )
          })}
          <Lib.C.SidebarItem
            isCollapsed={sidebar}
            name="Çıkış Yap"
            path="#"
            subItems={[]}
            active={false}
            activeSubItem=""
            icon="logout"
            className=""
            id="logout"
            permissions={ RolesTR as any}
            newPermissions={Permissions as any}
            group={permission}
          />
          <Gap mood="H" size={10} className="forTabletAndLess" />
          <div className="break forTabletAndLess" />
          <Gap mood="H" size={10} className="forTabletAndLess" />
          <Lib.C.SidebarItem
            isCollapsed={sidebar}
            name="Bildirimler"
            path="/notifications"
            subItems={[]}
            active={false}
            activeSubItem="notifications"
            icon="bell"
            className="forTabletAndLess"
            id="notifications"
            permissions={['Yönetim', 'Üretim Yönetim', 'Satış Yönetim']}
            newPermissions={['manage-messaging']}
            group={permission}
          />
          <Gap mood="H" size={20} />
          <div className="userInfo">{!sidebar ? <p style={{ margin: 0, fontWeight: 'bold' }}>{user.username}</p> : null}</div>
          <div className="collapsed">
            <div className="action-btn" onClick={() => (sidebar ? closeSidebar() : openSidebar())}>
              <Icon name={`arrow_${sidebar ? 'right' : 'left'}`} size={15} color="#fff" />
            </div>
            {!sidebar ? <span>Collapse Menu</span> : null}
          </div>
        </Lib.S.ScrollSection>
      </Lib.S.sidebarContainer>
    </>
  )
}
