import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import responseHandler from 'common/helpers/axiosResponeHandler'
import swal from 'sweetalert'

// BaseService.ts
export class BaseService {
  accessToken: string;
  private isRefreshing: boolean = false;
  private failedQueue: { resolve: (value?: any) => void; reject: (reason?: any) => void; config: any }[] = [];

  constructor() {
    const user = Storage.get<SavedUser>('user');
    if (!user || !user.accessToken) {
        //BaseService.dontAccept();
        localStorage.clear()
        window.location.href = `${window.location.origin}/authenticate/login`
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!);
  }

  async refreshToken(): Promise<void> {
    const user = Storage.get<SavedUser>('user');
    if (!user) {
      //BaseService.dontAccept();
      localStorage.clear()
      window.location.href = `${window.location.origin}/authenticate/login`
      return;
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const response = await ApiCaller({
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL!.concat('auth/refresh'),
        headers: { authorization: 'Bearer ' + user.refreshToken },
      });

      if (response.status === 201 && response.data.access_token) {
        this.accessToken = 'Bearer '.concat(response.data.access_token);
        user.accessToken = response.data.access_token;
        user.refreshToken = response.data.refresh_token;
        Storage.set<SavedUser>('user', user);
        this.processQueue(null, response.data.access_token);
      } else {
        this.processQueue('Refresh token failed', null);
        BaseService.dontAccept();
      }
      this.isRefreshing = false;
    }
  }

  private processQueue(error: any, token: string | null = null) {
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.config.headers['authorization'] = 'Bearer '.concat(token!);
        prom.resolve(ApiCaller(prom.config));
      }
    });

    this.failedQueue = [];
  }

  protected async apiCallWithRefresh(fn: () => Promise<any>, config: any): Promise<any> {
    try {
      return await fn();
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        try {
          await this.refreshToken();
          config.headers['authorization'] = this.accessToken;
          return await fn();
        } catch (err) {
          throw err;
        }
      } else {
          responseHandler(error, 'error', config?.customConfigs?.hidePopup)
          return error.response
      }
    }
  }

  static dontAccept() {
    throw 'not-acceptable';
  }
}

