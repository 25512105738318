import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class LocksService extends BaseService{
  endPoint = 'locks'
  subEndPoint = 'sub-locks'

  constructor() {
    super();
  }

  async create({
    title,
    image,
    categories,
    projectDepartment,
    rosinDepartment,
  }: Lib.T.Locks.CreateArgs): Promise<Lib.T.Locks.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint),
      headers: { authorization: this.accessToken },
      data: {
        title,
        image,
        categories,
        projectDepartment,
        rosinDepartment,
      },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 201 || !data.lock) {
        return { success: false }
      }

      return { success: true, data: data.lock }
    }, config);
  }

  async getList(args?: Lib.T.Locks.GetListArgs): Promise<Lib.T.Locks.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.locks) {
        swal({
          title: 'Alt locks oluşturulamadı. Lütfen tekrar deneyin.',
          icon: 'error',
          dangerMode: true,
        })
        return { success: false }
      }

      return { success: true, data: data.locks }
    }, config);
  }

  async delete(id: number, subLock?: boolean): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(subLock ? `${this.subEndPoint}/${id}` : `${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return false
      }

      return status === 200
    }, config);
  }

  async edit(id: number, args: Lib.T.Locks.EditArgs): Promise<Lib.T.Locks.EditResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.lock) {
        swal({
          text: 'Alt locks düzenlenemedi. Lütfen tekrar deneyin.',
          icon: 'error',
          dangerMode: true,
        })

        return { success: false }
      }

      return { success: true, data: data.lock }
    }, config);
  }

  async editSubLock(id: number, args: Lib.T.Locks.EditSubLockArgs): Promise<Lib.T.Locks.EditSubLockResult> {
    const config: IApiCallerConfig = {
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.subLock) {
        return { success: false }
      }

      return { success: true, data: data.subLock }
    }, config);
  }
  // create sublock
  async createSubLock(args: Lib.T.Locks.CreateSubLockArgs): Promise<Lib.T.Locks.CreateSubLockResponse> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndPoint),
      headers: { authorization: this.accessToken },
      data: {
        ...args,
      },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
        if (status !== 201 || !data.subLock) {
          return { success: false }
        }

        return { success: true, data: data.subLock }
    }, config);
  }

  async getSubLocks(args?: Lib.T.Locks.GetListArgs): Promise<Lib.T.Locks.GetSubLocks> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndPoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.subLocks) {
        return { success: false }
      }

      return { success: true, data: data.subLocks, total: data.total }
    }, config);
  }

  async findOneSubLock(id: number): Promise<Lib.T.Locks.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndPoint}/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.subLock) {
        return { success: false }
      }

      return {
        success: true,
        subLock: data.subLock,
      }
    }, config);
  }
}
