import { useEffect, useState } from 'react'
import * as Lib from '.'
import * as SubPiecesLib from '../../../sub-pieces/lib'

export const useAutomationItems = () => {
  const [keyword, setKeyword] = useState<string>('')
  const { automationList } = Lib.CO

  const [automation, setAutomation] = useState<SubPiecesLib.T.ListItem[]>([])
  const filter = (list: SubPiecesLib.T.ListItem[]) => {
    return list.filter(item => item.title.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
  }

  const makeLists = () => {
    setAutomation(filter(automationList))
  }

  useEffect(makeLists, [keyword])

  return {
    setKeyword,
    list: {
      automation,
    },
  }
}
