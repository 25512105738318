import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class MonacoPieces extends BaseService{
  endPoint = 'monaco-pieces'
  monacoProducts = 'monaco-products'

  constructor() {
    super();
  }

  async create(createArg: Lib.T.MonacoPieces.CreateArgs): Promise<Lib.T.MonacoPieces.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint),
      headers: { authorization: this.accessToken },
      data: {
        ...createArg,
      },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 201 || !data.monacoPiece) {
        return { success: false }
      }

      return { success: true, data: data.monacoPiece }
    }, config);
  }

  async findOne(id: number): Promise<Lib.T.MonacoPieces.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(`/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200) {
        return { success: false }
      }

      return { success: true, monacoPieces: data.monacoPiece }
    }, config);
  }

  async getList(subTypeId: number, args?: Lib.T.MonacoPieces.GetListArgs): Promise<Lib.T.MonacoPieces.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(
        args
          ? DOM.objectToSearch({
              subcategoryType: subTypeId,
              limit: args.limit || 99999999,
              ...args,
            })
          : `?subcategoryType=${subTypeId}&limit=9999999`,
      ),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.monacoPieces) {
        return { success: false }
      }

      return { success: true, monacoPieces: data.monacoPieces }
    }, config);
  }

  async getExcel(subTypeId: number, args?: Lib.T.MonacoPieces.GetListArgs): Promise<Lib.T.MonacoPieces.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(
        args
          ? DOM.objectToSearch({
            subcategoryType: subTypeId,
            limit: args.limit || 99999999,
            ...args,
          })
          : `?subcategoryType=${subTypeId}&limit=9999999`,
      ).concat(`&exportExcel=true`),
      headers: { authorization: this.accessToken, ContentType: 'blob' },
      responseType: 'arraybuffer'
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200) {
        return { success: false, result: [] }
      }
      return { success: true, result: data }
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return false
      }

      return status === 200
    }, config);
  }

  async edit(id: number, args: Lib.T.MonacoPieces.CreateArgs): Promise<Lib.T.MonacoPieces.EditResult> {
    const config: IApiCallerConfig = {
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.monacoPiece) {
        return { success: false }
      }

      return { success: true, data: data.monacoPiece }
    }, config);
  }

  async getSubBaklaWithWidth(width: string): Promise<Lib.T.MonacoPieces.SubBaklaResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/sub-baklas/${width}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.subBaklas) {
        return { success: false }
      }

      return { success: true, data: data.subBaklas }
    }, config);
  }

  async getLocksBaseOnCategoryTypeId(id: number): Promise<Lib.T.MonacoPieces.getLocksBaseOnCategoryTypeIdType> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.monacoProducts}/findLock/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200) {
        return { success: false, monacoProducts: [] }
      }

      return { success: true, monacoProducts: data.monacoProducts }
    }, config);
  }
}
