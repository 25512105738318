import { ModalProps } from 'antd'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationDepartmentService } from 'common/services'
import { Department } from 'common/services/lib/types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import * as Lib from '.'

export const useCreateDepartment = () => {
  const { setLoader } = useLoader()
  const history = useHistory()

  const [name, setName] = useState<string>('')
  const [departmentList, setDepartmentList] = useState([{ id: 0, title: '' }])
  const [departmentSelected, setDepartmentSelected] = useState<number>()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [department, setDepartment] = useState<Department.Department>()

  const [editMode, setEditMode] = useState<boolean>(false)

  const error = (msg: string) =>
    swal({
      text: msg,
      dangerMode: false,
      icon: 'error',
    })

  const validateForm = (): boolean => {
    if (!name) {
      error('please enter Department name')
      return false
    }
    return true
  }

  const createOrEdit = async () => {
    if (!validateForm()) {
      return
    }

    let success = false
    setLoader(true)

    const args = {
      name: name,
      parentId: departmentSelected,
    }

    if (editMode) {
      success = await edit(args)
    } else {
      success = await create(args)
    }
    setLoader(false)
    if (success) {
      return swal({
        icon: 'success',
        timer: 500,
        buttons: [false],
        className: 'rounded-swal',
      }).then(() => history.replace('/automation/departments'))
    }
  }

  const edit = async (args: { name: string; parentId?: number }): Promise<boolean> => {
    const service = new AutomationDepartmentService()
    const { success } = await service.edit(args, department?.id || 0)
    return success
  }

  const create = async (args: { name: string; parentId?: number }): Promise<boolean> => {
    const service = new AutomationDepartmentService()
    const { success } = await service.create(args)
    return success
  }

  const checkForEdit = async () => {
    const { href } = window.location
    if (!href.includes('edit')) {
      return
    }
    const id = href.split('/').pop()
    if (!id) {
      return
    }
    setEditMode(true)
    const service = new AutomationDepartmentService()
    const { success, department } = await service.findOne(parseInt(id))

    if (!success || !department) {
      return
    }

    setDepartment(department)
    const { name, parent } = department
    setName(name)
    parent && setDepartmentSelected(parent.id)
  }

  const deleteDepartment = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const success = await service.delete(department?.id!)
    if (success) {
      history.replace('/automation/departments')
      setLoader(false)
    }
    setLoader(false)
  }

  const toggleDeleteModal = () => {
    setDeleteModal(perv => !perv)
  }

  const addItemAtFirstIndex = (array: Lib.T.Item[], newItem: Lib.T.Item): Lib.T.Item[] => {
    return [newItem, ...array]
  }

  const getDepartmentList = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { success, data } = await service.getList('?isMain=true')
    setLoader(false)
    if (success && data) {
      const newItem: Lib.T.Item = { id: 0, title: '' }
      const departmentsList = data.map(item => ({
        id: item.id,
        title: item.name,
      }))
      setDepartmentList(addItemAtFirstIndex(departmentsList, newItem))
    }
  }

  const deleteModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: deleteModal,
    onCancel: () => setDeleteModal(false),
    className: 'noHeaderModal',
  }

  useEffect(() => {
    editMode && checkForEdit()
  }, [editMode])

  useAsyncEffect(checkForEdit, [editMode])
  useAsyncEffect(getDepartmentList, [])

  return {
    get: {
      name,
      editMode,
      deleteModalProps,
      department,
      departmentSelected,
      departmentList,
    },
    set: {
      setName,
      setDepartmentSelected,
    },
    on: {
      toggleDeleteModal,
      createOrEdit,
      deleteDepartment,
    },
  }
}
