export const RolesTR = [
  'Yönetim',
  'Satış Yönetim',
  'Üretim Yönetim',
  'Satış Temsilcisi',
  'Ar-Ge Müdürü',
  'Ar-Ge',
  'Reçine Departmanı',
  'Projet Departmanı',
  'Döküm Departmanı',
  'Tezgah Departmanı',
  'Tambur Departmanı',
  'Mine Departmanı',
  'Cila 1 Departmanı',
  'Cila 2 Departmanı',
  'Mağaza Departmanı',
  'Monaco',
  'other'
] as const
export const Permissions = [
  "*",
  "admin-access",
  "manage-user",
  "manage-customer",
  "manage-role",
  "manage-department",
  "manage-option",
  "manage-file",
  "manage-stone",
  "manage-chain",
  "manage-enamel",
  "manage-product",
  "manage-monacoProduct",
  "manage-piece",
  "manage-monacoPiece",
  "manage-order",
  "manage-lock",
  "manage-bakla",
  "manage-messaging",
  "manage-appConfig",
  "manage-area",
  "manage-categoryImage",
  "manage-defect",
  "manage-printer",
  "manage-printerTask",
  "manage-printerProblem",
  "read-printerReport",
  "manage-printerLog",
  "manage-productSubCategory",
  "manage-slider",
  "manage-slide",
  "manage-subBakla",
  "manage-subCategoryType",
  "manage-subLock",
  "manage-todoList",
  "manage-notification",
  "read-group",
  "manage-permission",
  "create-user",
  "read-user",
  "update-user",
  "delete-user",
  "create-customer",
  "read-customer",
  "update-customer",
  "delete-customer",
  "create-role",
  "read-role",
  "update-role",
  "delete-role",
  "create-department",
  "read-department",
  "update-department",
  "delete-department",
  "read-permission",
  "update-permission",
  "create-option",
  "read-option",
  "update-option",
  "delete-option",
  "upload-file",
  "upload-files",
  "create-stone",
  "read-stone",
  "update-stone",
  "delete-stone",
  "create-chain",
  "read-chain",
  "update-chain",
  "delete-chain",
  "create-enamel",
  "read-enamel",
  "update-enamel",
  "delete-enamel",
  "create-product",
  "read-product",
  "update-product",
  "delete-product",
  "change-name-product",
  "read-pending-product",
  "reject-product",
  "approve-product",
  "send-to-production-product",
  "create-monacoProduct",
  "read-monacoProduct",
  "update-monacoProduct",
  "delete-monacoProduct",
  "change-name-monacoProduct",
  "create-piece",
  "read-piece",
  "update-piece",
  "delete-piece",
  "create-monacoPiece",
  "read-monacoPiece",
  "update-monacoPiece",
  "delete-monacoPiece",
  "create-order",
  "read-order",
  "update-order",
  "delete-order",
  "read-one-order",
  "approve-order",
  "update-state-order",
  "my-orders-order",
  "stones-order",
  "profits-order",
  "deliver-order",
  "send-to-production-order",
  "problem-order",
  "locks-order",
  "baklas-order",
  "toggle-lock-order",
  "send-notification-order",
  "create-lock",
  "read-lock",
  "update-lock",
  "delete-lock",
  "create-bakla",
  "read-bakla",
  "update-bakla",
  "delete-bakla",
  "read-report",
  "create-group-messaging",
  "delete-group-messaging",
  "delete-group-users-messaging",
  "add-group-user-messaging",
  "read-appConfig",
  "update-appConfig",
  "create-area",
  "read-area",
  "update-area",
  "delete-area",
  "create-categoryImage",
  "read-categoryImage",
  "update-categoryImage",
  "delete-categoryImage",
  "create-defect",
  "read-defect",
  "update-defect",
  "delete-defect",
  "create-printer",
  "read-printer",
  "update-printer",
  "disable-printer",
  "delete-printer",
  "create-printerTask",
  "read-printerTask",
  "update-printerTask",
  "delete-printerTask",
  "create-printerProblem",
  "read-printerProblem",
  "update-printerProblem",
  "delete-printerProblem",
  "read-printerLog",
  "update-printerLog",
  "create-productSubCategory",
  "read-productSubCategory",
  "update-productSubCategory",
  "delete-productSubCategory",
  "read-slider",
  "update-slider",
  "create-slide",
  "read-slide",
  "update-slide",
  "delete-slide",
  "read-subBakla",
  "create-subBakla",
  "update-subBakla",
  "delete-subBakla",
  "create-subCategoryType",
  "read-subCategoryType",
  "update-subCategoryType",
  "delete-subCategoryType",
  "create-subLock",
  "read-subLock",
  "update-subLock",
  "delete-subLock",
  "create-todoList",
  "read-todoList",
  "update-todoList",
  "delete-todoList",
  "read-notification",
  "update-notification"
] as const
export const SecondaryPermissions = [
  '*-secondary',
  'admin-secondary-access',
  'manage-secondary-employee',
  'manage-secondary-employeeManager',
  'manage-secondary-employeeOfficer',
  'manage-secondary-officerOvertime',
  'manage-secondary-managerOvertime',
  'read-secondary-employeeManager',
  'create-secondary-employeeManager',
  'update-secondary-employeeManager',
  'read-secondary-leave-history-employeeManager',
  'update-secondary-leave-count-employeeManager',
  'read-secondary-employeeOfficer',
  'create-secondary-employeeOfficer',
  'update-secondary-employeeOfficer',
  'read-secondary-leave-history-employeeOfficer',
  'update-secondary-leave-count-employeeOfficer',
  'read-secondary-employee',
  'update-secondary-employee',
  'update-secondary-leave-count-employee',
  'read-secondary-all-employee',
  'update-secondary-all-employee',
  'manage-secondary-service',
  'create-secondary-service',
  'read-secondary-service',
  'update-secondary-service',
  'delete-secondary-service',
  'manage-secondary-leave',
  'create-secondary-leave',
  'read-secondary-leave',
  'create-secondary-for-other-leave',
  'create-secondary-for-self-leave',
  'update-secondary-leave',
  'accept-secondary-leave',
  'reject-secondary-leave',
  'delete-secondary-leave',
  'manage-secondary-leaveType',
  'create-secondary-leaveType',
  'read-secondary-leaveType',
  'update-secondary-leaveType',
  'delete-secondary-leaveType',
  'manage-secondary-department',
  'read-secondary-department',
  'create-secondary-department',
  'update-secondary-department',
  'delete-secondary-department',
  'manage-secondary-role',
  'read-secondary-role',
  'create-secondary-role',
  'update-secondary-role',
  'delete-secondary-role',
  'manage-secondary-permission',
  'read-secondary-permission',
  'update-secondary-permission',
  'read-secondary-officerOvertime',
  'delete-secondary-officerOvertime',
  'manage-secondary-overtime',
  'create-secondary-overtime',
  'read-secondary-overtime',
  'delete-secondary-employee-overtime',
  'create-secondary-managerOvertime',
  'read-secondary-managerOvertime',
  'delete-secondary-managerOvertime',
  'delete-secondary-overtime',
  'manage-secondary-neighbourhood',
  'read-secondary-neighbourhood',
  'create-secondary-neighbourhood',
  'update-secondary-neighbourhood',
  'delete-secondary-neighbourhood',
  'manage-secondary-leaveCountHistory',
  'read-secondary-leaveCountHistory',
  'update-secondary-leaveCountHistory',
]as const
export type RolesTRType = (typeof RolesTR)[number]
export type PermissionsType = (typeof Permissions)[number]
export type SecondaryPermissionsType = (typeof SecondaryPermissions)[number]

export const Managers = ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim']

export const RecineProjet = ['Reçine Departmanı', 'Projet Departmanı']
