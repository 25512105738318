import swal from 'sweetalert'

const responseHandler = (res: any, type: 'success' | 'error', hidePopup?: boolean) => {
  if (res?.message === 'Network Error' && hidePopup === undefined) {
    return swal({
      text: 'İnternet erişim hatası',
      dangerMode: true,
      icon: 'error',
    })
  }
  if (type.includes('error')) {
    const { response } = res
    if (response?.data?.message?.clientMessage || response?.data?.messageList) {
      if (response.data.message?.clientMessage) {
        if (response.data.message.clientMessage.includes('Teknik reng bulunamadı Lütfen önce teknik rengini girin.')) {
          return response
        }

        return swal({
          text: response?.data?.message.clientMessage,
          dangerMode: true,
          icon: 'error',
        })
      } else {
        let output = '';
        response?.data?.messageList?.forEach((message: any) => {
          output += message.clientMessage + '\n';
        })
        if (output) {
          return swal({
            text: output,
            dangerMode: true,
            icon: 'error',
          })
        }
      }
    }

    if (!response) {
      swal({
        text: 'İnternet erişim hatası',
        dangerMode: true,
        icon: 'error',
      })
      return false
    }
    if (response.status >= 500) {
      swal({
        text: 'İnternet erişim hatası',
        dangerMode: true,
        icon: 'error',
      })

      return false
    }
    if (response.status === 401 && window.location.pathname !== '/authenticate/login') {
      return 401
    }
    return response
  }
}

export default responseHandler
