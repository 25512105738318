import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import {
  EmployeesService,
  AutomationNeighbourhoodService,
  AutomationServiceIdsService,
  AutomationDepartmentService,
  AutomationRoleService,
  MediaService,
  LeaveService,
} from 'common/services'
import { LeaveType } from 'common/services/lib/types/leave'
import { EditEmployeeArgs, CreateEmployeeArgs } from 'common/services/lib/types/employees'
import { useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import { imageAddresser } from 'common/helpers/image.helper'
import { Moment } from 'moment'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

export const useEmployees = () => {
  const { setLoader } = useLoader()
  const history = useHistory()

  //edit states
  const modalInitialState: Lib.T.ModalPropsType = {
    kind: 'post',
    title: '',
    editProps: {
      id: 0,
      avatar: '',
      employeeInformation: {
        firstName: '',
        lastName: '',
        personnelCode: '',
        address: '',
        description: '',
        birthday: '',
        hireDate: '',
        gender: '',
        leaveNumber: undefined,
        manager: {
          email: '',
          firstName: '',
          id: undefined,
          lastName: '',
        },
        neighbourhood: {
          id: undefined,
          name: '',
        },
        service: {
          id: undefined,
          name: '',
        },
        rate: 0,
        employeeIds: [],
        user: {
          id: undefined,
          name: '',
        },
        department: {
          id: undefined,
        },
        role: {
          id: undefined,
        },
        adminDescription: '',
      },
      isManager: false,
      serviceId: 0,
      neighbourhoodId: 0,
    },
  }

  // const [rating, setRating] = useState<number>(0)
  // const [hoverStar, setHoverStar] = useState<number>(0)
  const [switchBtn, setSwitchBtn] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ name: '', departmentId: undefined, roleId: undefined })
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['', 'Ad', 'Kod', 'Departman', 'Görev', '']
  const tableSizes = ['3%', 'unset', 'unset', 'unset', 'unset', 'unset', '7%']
  const [name, setName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [IDCode, setIDCode] = useState('')
  const [birthday, setBirthday] = useState('')
  const [hireDate, setHireDate] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isManager, setIsManager] = useState(false)
  const [neighbourhoodIdSelected, setNeighbourhoodIdSelected] = useState<number | undefined>()
  const [serviceIdSelected, setServiceIdSelected] = useState<number | undefined>()
  const [description, setDescription] = useState('')
  const [leaveDescription, setLeaveDescription] = useState('')
  const [avatar, setAvatar] = useState<File | null>(null)
  const [defaultAvatar, setDefaultAvatar] = useState<string>('')
  const [modalProps, setModalProps] = useState<Lib.T.ModalPropsType>(modalInitialState)
  const [neighbourhoodIds, setNeighbourhoodIds] = useState([{ id: 0, title: '' }])
  const [managerIds, setManagerIds] = useState([{ id: 0, title: '' }])
  const [managerIdSelected, setManagerIdSelected] = useState<number | undefined>()
  const [serviceIds, setServiceIds] = useState([{ id: 0, title: '' }])
  const [departmentIdSelected, setDepartmentIdSelected] = useState<number | undefined>()
  const [departmentIds, setDepartmentIds] = useState([{ id: 0, title: '' }])
  const [roles, setRoles] = useState([{ id: 0, title: '' }])
  const [roleIdSelected, setRoleIdSelected] = useState<number | undefined>()
  const [employeesSelected, setEmployeesSelected] = useState<string[]>([])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [address, setAddress] = useState('')
  const [leaveNumber, setLeaveNumber] = useState('')
  const [gender, setGender] = useState('')
  const [filterRoleList, setFilterRoleList] = useState([{ id: 0, title: '' }])
  const [typeLeave, setTypeLeave] = useState([{ id: 0, title: '', isHourly: false, mustCalculate: false }])
  const [typeLeaveSelected, setTypeLeaveSelected] = useState<number>()
  const [isHourly, setIsHourly] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [date, setDate] = useState<string | undefined | Moment>('')
  const [startHour, setStartHour] = useState<string | undefined | Moment>('')
  const [dayCount, setDayCount] = useState<number>()
  const [endHour, setEndHour] = useState<string | undefined | Moment>('')
  const [isEmpty, setIsEmpty] = useState(false)
  const [leaveIsEmpty, setLeaveIsEmpty] = useState(false)
  const [mustCalculate, setMustCalculate] = useState(false)
  const [paginateLoader, setPaginateLoader] = useState(false)

  const genders = [
    { id: 0, title: 'Erkek' },
    { id: 1, title: 'Kadın' },
  ]

  const tableIdIndex = 8

  const handleModal = () => {
    setModalProps(modalInitialState)
    setShowEditModal(prev => !prev)
    setGender('')
    setRoleIdSelected(undefined)
    setDepartmentIdSelected(undefined)
    setManagerIdSelected(undefined)
    setServiceIdSelected(undefined)
    setNeighbourhoodIdSelected(undefined)
    setLeaveNumber('')
    setIsEmpty(false)
  }

  const handleCancelLeaveModal = () => {
    setModalProps(modalInitialState)
    setShowLeaveModal(prev => !prev)
    setLeaveDescription('')
    setTypeLeaveSelected(undefined)
    setDayCount(undefined)
    setDate(undefined)
    setEndHour(undefined)
    setStartHour(undefined)
    setIsHourly(false)
    setEndDate('')
    setStartDate('')
    setLeaveIsEmpty(false)
  }

  const addItemAtFirstIndex = (array: Lib.T.Item[], newItem: Lib.T.Item): Lib.T.Item[] => {
    return [newItem, ...array]
  }

  const getEmployeesByGroup = async () => {
    const service = new EmployeesService()
    if (paginateLoader) {
      setLoader(false)
    } else {
      setLoader(true)
    }
    const { success, employees, total } = await service.getList({
      limit: paginate.limit,
      name: query.name,
      departmentId: query.departmentId,
      roleId: query.roleId,
    })
    setPaginateLoader(false)
    setLoader(false)
    if (success && employees) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...employees.map(employee => {
          return [
            <Lib.C.TableImage src={employee.avatar ? imageAddresser(employee.avatar.imageThumbnail, true) : '/images/png/avatar.png'} />,
            <div>{`${employee?.firstName} ${employee?.lastName}`}</div>,
            <div>{employee?.personnelCode}</div>,
            <div>{employee?.user?.userToRoles[0].department.name ?? '-'}</div>,
            <div>{employee?.user?.userToRoles[0].role.name ?? '-'}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton icon="detail" callback={() => history.push(`/automation/leave/historyLeave?id=${employee.id}`)} />
              <Lib.C.TableButton
                icon="application_for_leave"
                callback={() => {
                  handleCancelLeaveModal()
                  setModalProps({
                    ...modalProps,
                    kind: 'create-leave',
                    editProps: {
                      id: employee?.id,
                      name: employee?.firstName + ' ' + employee?.lastName,
                      leaveNumber: employee?.leaveNumber,
                    },
                  })
                  setLeaveNumber(String(employee?.leaveNumber) ?? '')
                }}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => getEmployee(employee?.id)} />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const getEmployee = async (id: number) => {
    const service = new EmployeesService()
    setLoader(true)
    const { success, employee } = await service.getEmployee({ isManager: true }, id)
    setLoader(false)
    if (success && employee) {
      handleModal()
      setModalProps({
        kind: 'edit',
        editProps: {
          id: employee?.id,
          avatar: employee?.avatar?.imageThumbnail,
          email: employee?.email,
          phone: employee?.phone,
          name: employee?.firstName,
          employeeInformation: {
            firstName: employee?.firstName,
            lastName: employee?.lastName,
            personnelCode: employee?.personnelCode,
            address: employee?.address,
            description: employee?.description ?? '',
            birthday: employee?.birthday,
            hireDate: employee?.hireDate,
            gender: employee.gender,
            leaveNumber: employee.leaveNumber,
            manager: {
              id: employee?.manager?.id,
            },
            neighbourhood: {
              id: employee?.neighbourhood?.id,
            },
            service: {
              id: employee?.service?.id,
            },
            rate: employee?.rate,
            adminDescription: employee?.employeeDescriptions[0]?.description,
            department: {
              id: employee.user?.userToRoles[0]?.department?.id,
            },
            role: {
              id: employee.user?.userToRoles[0]?.role?.id,
            },
          },
          neighbourhoodId: employee?.neighbourhood?.id,
          serviceId: employee?.service?.id,
          isManager: employee?.isManager,
        },
      })
    }
  }

  const getNeighbourhoodIds = async () => {
    const service = new AutomationNeighbourhoodService()
    setLoader(true)
    const { success, neighbourhoods } = await service.getList()
    setLoader(false)
    if (success && neighbourhoods) {
      const newItem: Lib.T.Item = { id: 0, title: '' }
      const neighbourhoodItems = neighbourhoods.map(item => ({
        id: item?.neighbourhood_id,
        title: item?.neighbourhood_name,
      }))
      setNeighbourhoodIds(addItemAtFirstIndex(neighbourhoodItems, newItem))
    }
  }

  const getServiceIds = async () => {
    const service = new AutomationServiceIdsService()
    setLoader(true)
    const { success, services } = await service.getList()
    setLoader(false)
    if (success && services) {
      const newItem: Lib.T.Item = { id: 0, title: '' }
      const serviceIdItems = services.map(item => ({
        id: item?.id,
        title: item?.name,
      }))
      setServiceIds(addItemAtFirstIndex(serviceIdItems, newItem))
    }
  }

  const getManagerIds = async () => {
    const service = new EmployeesService()
    setLoader(true)
    const { success, employees } = await service.getList({
      isManager: true,
    })
    setLoader(false)
    if (success && employees) {
      setManagerIds(() => {
        return employees.map(item => ({
          id: item?.id ?? 0,
          title: item.firstName || item.lastName ? `${item?.firstName ?? ''} ${item?.lastName ?? ''}` : '',
        }))
      })
    }
  }

  const getDepartments = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { data, success } = await service.getList('?isMain=true')
    setLoader(false)
    if (success && data) {
      setDepartmentIds(() => {
        return data.map(item => ({
          id: item?.id,
          title: item?.name,
        }))
      })
    }
  }

  const getRolesList = async () => {
    const service = new AutomationRoleService()
    setLoader(true)
    const { roles, success } = await service.getList()
    setLoader(false)
    if (success && roles) {
      setRoles(() => {
        return roles.map(item => ({
          id: item?.id,
          title: item?.name,
        }))
      })
    }
  }

  const getFilterRolesList = async () => {
    const service = new AutomationRoleService()
    setLoader(true)
    const { roles, success } = await service.getList({
      departmentId: query.departmentId,
    })
    setLoader(false)
    if (success && roles) {
      setFilterRoleList(() => {
        return roles.map(item => ({
          id: item?.id,
          title: item?.name,
        }))
      })
    }
  }

  const upload = async (image: File): Promise<string> => {
    const endPoint = 'automation/minio/upload'
    if (!image) {
      return swal({
        text: 'please select an image',
        icon: 'error',
      })
    }

    const filesize = parseFloat((image.size / 1024 / 1024).toFixed(1))

    if (filesize > 2.5) {
      return swal({
        text: 'file size is more thn 2.5 megabyte',
        icon: 'error',
      })
    }
    const mediaService = new MediaService()
    const result = await mediaService.upload(image, 'user', endPoint)
    const { name, success } = result
    if (!success || !name) {
      return swal({
        text: 'something went wrong',
        icon: 'error',
      })
    }
    return name
  }

  const handleSwal = (success: boolean) => {
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        setName('')
        setEmail('')
        setPhone('')
        setGender('')
        setBirthday('')
        setHireDate('')
        setDescription('')
        setIsManager(false)
        setShowEditModal(false)
      })
      setLoader(false)
    }
  }

  const removeNullOrEmptyValuesForEdit = (obj: EditEmployeeArgs) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== ''))
  }

  const EditEmployee = async () => {
    const service = new EmployeesService()
    let uploadedImage: string = ''

    if (avatar) {
      try {
        setLoader(true)
        uploadedImage = await upload(avatar)
      } catch (e) {
        return
      } finally {
        setLoader(false)
      }
    }

    setLoader(true)

    const args: EditEmployeeArgs = {
      managerId: managerIdSelected,
      personnelCode: IDCode,
      avatar: uploadedImage,
      firstName: firstName,
      lastName: lastName,
      address: address,
      description: description,
      birthday: birthday,
      hireDate: hireDate,
      gender: gender,
      // rate: rating,
      serviceId: serviceIdSelected,
      neighbourhoodId: neighbourhoodIdSelected,
      leaveNumber: Number(leaveNumber),
      isManager: isManager,
      secondRole: {
        roleId: roleIdSelected,
        departmentId: departmentIdSelected,
      },
    }

    const cleanArgs = removeNullOrEmptyValuesForEdit(args)

    const { success } = await service.editEmployee(cleanArgs, modalProps.editProps.id)

    handleSwal(success)

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const removeNullOrEmptyValuesForCreate = (obj: CreateEmployeeArgs) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== ''))
  }

  const getNestedProperty = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  const validateCreateEmployeeArgs = (args: CreateEmployeeArgs): boolean => {
    const requiredFields: { field: string; label: string }[] = [
      { field: 'firstName', label: 'İsim' },
      { field: 'lastName', label: 'Soy isim' },
      { field: 'email', label: 'Mail' },
      { field: 'personnelCode', label: 'Personel Kodu' },
      { field: 'gender', label: 'Cinsiyet' },
      { field: 'birthday', label: 'Doğum tarihi' },
      { field: 'phone', label: 'Telefon' },
      { field: 'secondRole.departmentId', label: 'Departman' },
      { field: 'secondRole.roleId', label: 'Görev' },
      { field: 'managerId', label: 'Yönetici' },
      { field: 'hireDate', label: 'İşe Alma Tarihi' },
    ]

    for (const { field } of requiredFields) {
      const value = getNestedProperty(args, field)

      if (value === null || value === undefined || value === '' || value === 0) {
        swal({
          text: 'lütfen tüm alanı doldurun.',
          icon: 'error',
        })
        setIsEmpty(true)
        return false
      }
    }

    setIsEmpty(false)
    return true
  }

  const CreateEmployee = async () => {
    const service = new EmployeesService()
    let uploadedImage: string = ''

    if (avatar) {
      try {
        setLoader(true)
        uploadedImage = await upload(avatar)
      } catch (e) {
        return
      } finally {
        setLoader(false)
      }
    }

    setLoader(true)

    const args: CreateEmployeeArgs = {
      managerId: managerIdSelected ?? null,
      email: email,
      avatar: uploadedImage ?? null,
      personnelCode: IDCode,
      phone: phone,
      firstName: firstName,
      lastName: lastName,
      address: address ?? null,
      description: description ?? null,
      birthday: birthday ?? null,
      hireDate: hireDate,
      // rate: rating,
      isManager: isManager,
      serviceId: serviceIdSelected ?? null,
      leaveNumber: Number(leaveNumber) ?? null,
      neighbourhoodId: neighbourhoodIdSelected ?? null,
      gender: gender,
      secondRole: {
        roleId: roleIdSelected ?? 0,
        departmentId: departmentIdSelected ?? 0,
      },
    }

    const cleanArgs = removeNullOrEmptyValuesForCreate(args)

    if (!validateCreateEmployeeArgs(cleanArgs)) {
      setLoader(false)
      return
    }

    const success = await service.createEmployee(cleanArgs)

    handleSwal(success)

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const getLeaveType = async () => {
    const service = new LeaveService()
    setLoader(true)
    const { success, leaveTypes } = await service.getLeaveTypeList()
    if (!success || !leaveTypes) {
      return
    }
    setTypeLeave(() => {
      return leaveTypes.map((item: LeaveType) => ({
        id: item.leaveType_id,
        title: item.leaveType_type,
        isHourly: item.leaveType_isHourly,
        mustCalculate: item.leaveType_mustCalculate,
      }))
    })
    setLoader(false)
  }

  const mustCalculateHandler = (leaveList: Lib.T.leaveList[]) => {
    leaveList.map(item => {
      if (item.id == typeLeaveSelected) {
        setMustCalculate(item.mustCalculate)
      }
    })
  }

  const CreateLeave = async (id: number) => {
    const service = new LeaveService()
    const start = moment(startHour).format('HH:mm')
    const end = moment(endHour).format('HH:mm')
    const dateForHour = moment(date).format('YYYY-MM-DD')

    const isAfterHour = moment(moment(dateForHour + ' ' + start).format()).isAfter(moment(dateForHour + ' ' + end).format())
    const isAfterDay = moment(startDate).isAfter(endDate)

    if (isHourly) {
      if (!Boolean(date) || !Boolean(startHour) || !Boolean(endHour)) {
        swal({
          text: 'lütfen tüm alanı doldurun.',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }

      if (isAfterHour) {
        swal({
          text: 'Başlangıç ​​tarihi bitiş tarihinden büyük olamaz',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }
    } else {
      if (!Boolean(startDate) || !Boolean(endDate) || !Boolean(dayCount)) {
        swal({
          text: 'lütfen tüm alanı doldurun.',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }

      if (isAfterDay) {
        swal({
          text: 'Başlangıç ​​tarihi bitiş tarihinden büyük olamaz',
          icon: 'error',
        })
        setLeaveIsEmpty(true)
        return
      }
    }

    const leaveCount = modalProps.editProps.leaveNumber ?? 0

    if (mustCalculate) {
      if (dayCount && leaveCount) {
        if (dayCount > leaveCount) {
          const result = await swal({
            text: 'Seçilen gün sayısı kalan yıllık izinden fazladir. İzin kaydı yaptırmaya emin misiniz?',
            icon: 'warning',
            buttons: {
              cancel: {
                text: 'İptal',
                value: false,
                visible: true,
                className: 'btn-secondary red-bg',
                closeModal: true,
              },
              confirm: {
                text: 'Evet',
                value: true,
                visible: true,
                className: 'btn-primary',
                closeModal: true,
              },
            },
            dangerMode: true,
          })

          if (!result) return
        }
      }
    }

    setLoader(true)

    const args = {
      leaveForId: id ? id : 0,
      typeId: typeLeaveSelected ? typeLeaveSelected : 0,
      start: isHourly ? moment(date + ' ' + start).format() : startDate,
      end: isHourly ? moment(date + ' ' + end).format() : endDate,
      dayCount: isHourly ? 0 : dayCount ? dayCount : 0,
      description: leaveDescription,
    }
    const { success: createSuccess } = await service.createOrEdit(args)
    if (createSuccess) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        setShowLeaveModal(false)
      })
      setLoader(false)
    }

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  useEffect(() => {
    setName(modalProps.editProps.name ?? '')
    setFirstName(modalProps.editProps?.employeeInformation?.firstName ?? '')
    setLastName(modalProps.editProps?.employeeInformation?.lastName ?? '')
    setIDCode(modalProps.editProps?.employeeInformation?.personnelCode ?? '')
    setBirthday(modalProps.editProps?.employeeInformation?.birthday ?? '')
    setPhone(modalProps.editProps.phone ?? '')
    setEmail(modalProps.editProps.email ?? '')
    setIsManager(modalProps.editProps.isManager ?? false)
    setNeighbourhoodIdSelected(modalProps.editProps ? modalProps.editProps.neighbourhoodId : undefined)
    setServiceIdSelected(modalProps.editProps.serviceId ?? undefined)
    setDescription(modalProps.editProps?.employeeInformation?.description ? modalProps.editProps?.employeeInformation?.description : '')
    setGender(modalProps.editProps.employeeInformation?.gender ?? '')
    setAddress(modalProps.editProps.employeeInformation?.address ?? '')
    setManagerIdSelected(modalProps.editProps.employeeInformation?.manager?.id ?? undefined)
    setNeighbourhoodIdSelected(modalProps.editProps.employeeInformation?.neighbourhood?.id ?? undefined)
    setServiceIdSelected(modalProps.editProps.employeeInformation?.service?.id ?? undefined)
    // setRating(modalProps.editProps.employeeInformation?.rate ?? 0)
    setHireDate(modalProps.editProps.employeeInformation?.hireDate ?? '')
    setDefaultAvatar(modalProps.editProps.avatar ?? '')
    setLeaveNumber(String(modalProps.editProps.employeeInformation?.leaveNumber) ?? '')
    setDepartmentIdSelected(modalProps.editProps.employeeInformation?.department?.id ?? undefined)
    setRoleIdSelected(modalProps.editProps.employeeInformation?.role?.id ?? undefined)
  }, [modalProps])

  useEffect(() => setTableData([]), [fetchAgain])
  useEffect(() => {
    ;(async () => {
      await getNeighbourhoodIds()
      await getServiceIds()
      await getManagerIds()
      await getDepartments()
      await getRolesList()
      await getLeaveType()
    })()
  }, [])

  useEffect(() => {
    if (Boolean(query.departmentId)) {
      ;(async () => {
        await getFilterRolesList()
      })()
    }
  }, [query.departmentId])

  useEffect(() => {
    mustCalculateHandler(typeLeave)
  }, [typeLeaveSelected])

  useAsyncEffect(getEmployeesByGroup, [paginate, fetchAgain])

  return {
    val: {
      switchBtn,
      showEditModal,
      name,
      firstName,
      lastName,
      IDCode,
      birthday,
      hireDate,
      phone,
      email,
      neighbourhoodIdSelected,
      serviceIdSelected,
      description,
      modalProps,
      modalInitialState,
      isManager,
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
      employeesSelected,
      // rating,
      // hoverStar,
      gender,
      genders,
      avatar,
      defaultAvatar,
      managerIds,
      managerIdSelected,
      departmentIds,
      departmentIdSelected,
      roles,
      roleIdSelected,
      leaveNumber,
      filterRoleList,
      address,
      typeLeaveSelected,
      isHourly,
      startDate,
      endDate,
      date,
      startHour,
      dayCount,
      endHour,
      leaveDescription,
      showLeaveModal,
      isEmpty,
      leaveIsEmpty,
      paginateLoader,
    },
    set: {
      setSwitchBtn,
      setName,
      setFirstName,
      setLastName,
      setIDCode,
      setBirthday,
      setHireDate,
      setPhone,
      setEmail,
      setNeighbourhoodIdSelected,
      setServiceIdSelected,
      setDescription,
      setModalProps,
      setIsManager,
      setQuery,
      setFetchAgain,
      setEmployeesSelected,
      // setRating,
      // setHoverStar,
      setGender,
      setAvatar,
      setDefaultAvatar,
      setManagerIdSelected,
      setDepartmentIdSelected,
      setRoleIdSelected,
      setLeaveNumber,
      setAddress,
      setTypeLeave,
      setTypeLeaveSelected,
      setIsHourly,
      setStartDate,
      setEndDate,
      setDate,
      setStartHour,
      setDayCount,
      setEndHour,
      setLeaveDescription,
      setShowLeaveModal,
    },
    on: {
      handleModal,
      EditEmployee,
      handleShowMore,
      CreateEmployee,
      handleCancelLeaveModal,
      CreateLeave,
    },
    get: {
      query,
      neighbourhoodIds,
      serviceIds,
      paginate,
      totalData,
      typeLeave,
    },
  }
}
