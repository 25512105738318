import { mediaQueries as mq } from 'common/constants/media-queries'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10px 20px;
  .tabs {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    font-weight: bold;
  }
  .tab {
    cursor: pointer;
    font-size: 0.9rem;
    text-align: center;
    width: 80px;
    height: 36px;
    line-height: 36px;

    border: 1px solid #EEEEEE;
    border-radius: 8px;
    background: #fff;
  }
  .active {
    color: var(--accent);
    border: 1px solid var(--accent);
    border-radius: 8px;
    background: rgba(0, 78, 66, 0.1);
  }
  .navContainer{
    width: 250px;
    .nav{
      width: 120px;
      height: 36px;
      border: 1px solid #EEEEEE;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;
    }
  }
`

export const notesContainer = styled.div`
  width: 100%;
  ${mq.max[576]} {
    padding-bottom: 45px;
  }

  .checkboxTask{
    div>label>span{
      margin:0;
      border: 1px solid rgb(0 0 0 / 10%); 
    }
    div > label > input:checked + span.visual{
      background: rgba(0, 78, 66, 0.1);
    }
    label > input:checked + span.visual > svg{
      width: 10px;
      height: 10px;
    }
    label > input:checked + span.visual > svg > path {
      stroke: #004E42
    }
    span{
      font-size: 14px;
    }
    .checked{
      text-decoration: line-through;
    }
  }
  
  > div.body {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
    .card{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #EEEEEE;
      background: #fff;
      border-radius: 0;
      height: 234px;
      padding: 10px;
      cursor: pointer;
      
      .task{
        background: rgba(229, 237, 236, 1);
        border-radius: 4px;
        height: 24px;
        padding: 0 5px;
        color: var(--accent);
        font-size: 12px;
        line-height: 24px;
      }
    }
    
    .monthCardHeader{
      background: #fff;
      border-radius: 10px 10px 0 0;
      border: 1px solid #EEEEEE;
      height: 47px;
      display: flex;
      border-bottom: 0;
      
      >div{
        width: 14.28%;
        font-size: 14px;
        color: #979797;
        border-right: 1px solid #EEEEEE;
        text-transform: uppercase;
        height: 47px;
        display: flex;
        align-items: center;
        padding: 10px
      }
      >div:last-child{
        border:0
      }
    }
    .monthCard{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #EEEEEE;
      background: #fff;
      border-radius: 0;
      width: 14.28%;
      height: 131px;
      padding: 10px;
      cursor: pointer;
      
      >span{
        text-transform: uppercase;
        color: #979797;
        font-size: 21px;
      }
      
      .task{
        background: rgba(229, 237, 236, 1);
        border-radius: 4px;
        height: 24px;
        padding: 0 5px;
        color: var(--accent);
        font-size: 12px;
        line-height: 24px;
      }
    }
    
    .weekCard{
      background: #fff;
      border-radius: 8px;
      width: 49.5%;
      padding: 10px;
      margin-bottom: 10px;
      
      .title{
        background: #004E42;
        border-radius: 4px;
        padding: 3px 10px;
        color:#fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .notesContainer{
        height: 234px;
        overflow-y: scroll;
        position: relative;
        
        .spinLoader{
          position: absolute;
          right: 40%;
          left: 40%;
          top: 50%;
        }
      }
      
      .notesContainer::-webkit-scrollbar {
        width: 2px;
        background-color: #004e42;
      }
    
      .notesContainer::-webkit-scrollbar-thumb {
        background: #004e42;
        border-radius: 5px;
      }
      
      .sendNoteContainer{
        border: 1px #CCCCCC solid;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        padding: 10px;
        font-size: 12px;
        background: #FAFAFA;
        
        input{
          color: #979797;
          border: 0;
          background: #FAFAFA;
          outline: none;
          height: 21px;
        }
        input::placeholder{
          color: #979797
        }
      }
      .button {
          background-color: var(--accent);
          width: 45px;
          height: 45px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: none;
        }
    }
  }
`

export const modalContainer = styled.div`
  .card{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 78, 66, 0.1);
    padding: 10px;
    width: 100px;
    height: 100px;
    text-align: center;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
  }
  .cardSelected{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100px;
    height: 100px;
    text-align: center;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--accent);
    color: #fff
  }
`

export const actionsContainer = styled.div`
  .order{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`