import * as Lib from './lib'
import { BaseService } from './BaseService';
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import { FullProduct } from './lib/types/product'

export class ProductService extends BaseService {
  endpoint = 'refactored-products/'
  oldEndpoint = 'products/'
  customerImageEndpoint = 'customer-orders/'

  constructor() {
    super();
  }

  async create(data: Lib.T.Product.CreateOrEditArgs): Promise<Lib.T.Product.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint),
      headers: { authorization: this.accessToken },
      data,
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data: responseData } = response
      if (status !== 201) {
        return {
          success: false,
          error: responseData,
        }
      }
      return {
        success: true,
        id: responseData.product.id,
        error: null,
      }
    }, config);
  }
  async createMonacoProduct(data: Lib.T.Product.CreateMonacoArg): Promise<Lib.T.Product.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('monaco-products'),
      headers: { authorization: this.accessToken },
      data,
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)

      const { status, data: monacoProduct } = response
      if (status !== 201) {
        return {
          success: false,
          error: monacoProduct,
        }
      }

      return {
        success: true,
        id: monacoProduct.id,
        error: null,
      }
    }, config);
  }
  async edit(
    data: Lib.T.Product.CreateOrEditArgs,
    id: number,
  ): Promise<{ success: boolean; priceCategory: { id: number; minPrice: number; maxPrice: number } | null; product: FullProduct | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat(id.toString()),
      headers: { authorization: this.accessToken },
      data,
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data: result } = response

      if (status !== 200) {
        return { success: false, priceCategory: null, product: null }
      }

      return { success: true, priceCategory: result.product.priceCategory, product: result.product }
    }, config);
  }

  async updateProductCode(data: Lib.T.Product.UpdateProductCodeArgs, id: number,): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat(id.toString()).concat('/update-name'),
      headers: { authorization: this.accessToken },
      data,
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response

      return status === 200
    }, config);
  }

  async customerImage(data: Lib.T.Product.CustomerImage, id: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.customerImageEndpoint).concat(id.toString()),
      headers: { authorization: this.accessToken },
      data,
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response

      if (status !== 200) {
        return { success: false }
      }

      return { success: true }
    }, config);
  }

  async readAll(args: Lib.T.Product.ReadAllArgs): Promise<Lib.T.Product.ReadAllResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(DOM.objectToSearch(args)),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200) {
        return { success: false }
      }

      return {
        success: true,
        products: data.products,
        total: data.total,
        alreadyAddedProducts: data.alreadyAddedProducts,
        favoriteProductIds: data.favoriteProductIds,
        viewedFavoriteProductsIds: data.viewedFavoriteProductsIds,
      }
    }, config);
  }

  async pending(args?: Lib.T.Product.PendingArgs): Promise<Lib.T.Product.PendingResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.oldEndpoint)
        .concat('pending')
        .concat(DOM.objectToSearch(args ? args : {})),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, products: data.products, total: data.total }
    }, config);
  }

  async approveProduct(list: number[]): Promise<Lib.T.Product.SendToProductionResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat('approve'),
      headers: { authorization: this.accessToken },
      data: { products: list },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return {
          success: false,
        }
      }

      return { success: true }
    }, config);
  }

  async sendToProduction(list: number[]): Promise<Lib.T.Product.SendToProductionResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat('sendToProduction'),
      headers: { authorization: this.accessToken },
      data: { products: list },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      const incompleteProducts = data?.incompleteProducts

      if (status !== 200) {
        return {
          success: false,
        }
      }

      if (incompleteProducts) {
        return {
          success: false,
          incompleteProducts,
        }
      }

      return { success: true }
    }, config);
  }

  async checkForProduction(id: number | string): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat(id.toString()).concat('/checkSendToProduction'),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      return status === 200
    }, config);
  }

  async findOne(id: number, karat?: string): Promise<Lib.T.Product.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id)!.concat(karat ? '?karat=' + karat : ''),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status === 200 && data.product) {
        return {
          success: true,
          data: data.product,
          stones: data.stones,
          allData: data,
        }
      }
      return { success: false }
    }, config);
  }

  async assignTechnicalColor(id: number, body: Lib.T.Product.AssignTechnicalColor[]): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id + '/setTechnicalColor'),
      headers: { authorization: this.accessToken },
      data: {
        stones: body,
      },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status === 200) {
        return true
      }
      return false
    }, config);
  }

  async confirm(id: number): Promise<{ success: boolean; error: any }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id).concat('/confirm'),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { data } = response
      if (data.status === 200) {
        return { success: true, error: null }
      }

      return { success: false, error: data.data }
    }, config);
  }

  async disableProduct(id: number, disabled: boolean): Promise<{ success: boolean; product: FullProduct | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id).concat('/disable'),
      headers: { authorization: this.accessToken },
      data: { disabled },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { data } = response
      if (data.status === 200) {
        return { success: true, product: null }
      }

      return { success: false, product: data.data }
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { data } = response
      if (data.status === 200) {
        return true
      }

      return false
    }, config);
  }

  async copy(id: number, productCode: string): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id + '/getVariant'),
      headers: { authorization: this.accessToken },
      data: {
        productCode,
      },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status === 200) {
        return true
      }
      return false
    }, config);
  }
}
