import { RootState } from 'common/store/root-reducer'
import { GET_UNREAD_NOTIFICATION_SUCCESS } from 'common/constants/action-types'
import { UNHANDLED } from 'common/constants/errors'
import { doneTaskSuccessfully } from 'common/helpers/doneTaskSuccessfully'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { NotificationsService } from 'common/services'
import { GetListArgs } from 'common/services/lib/types/notifications'
import { NotificationsKey } from 'common/typing/notificationsKey'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/tr'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import * as Lib from './index'
import { onForegroundMessage } from 'firebase'

export const useNotifications = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState<Lib.T.INotificationData[]>([])
  const [pagination, setPagination] = useState<GetListArgs>({ page: 1, limit: 60 })
  const [notificationTotalData, setNotificationTotalData] = useState(0)
  const { setLoader, loader } = useLoader()
  const { unReadNotifications } = useSelector((state: RootState) => state.globals)

  const fetchNotifications = async () => {
    setLoader(true)
    const requestBody = new NotificationsService()
    await requestBody
      .getNotifications(pagination)
      .then(res => {
        if (res.data) {
          const list = _.sortBy(res.data, [notify => notify.read === true])
          setData(perv => [...perv, ...(list as any)])
          setNotificationTotalData(res.total)
        } else setData([])
      })
      .catch(() => {
        return swal({
          text: UNHANDLED,
          icon: 'error',
          dangerMode: true,
        })
      })
      .finally(() => setLoader(false))
  }

  // useEffect(() => {
  //   onForegroundMessage()
  //     .then(payload => {
  //       console.log('Received foreground message: ', payload)
  //     })
  //     .catch(err => console.log('An error occured while retrieving foreground message. ', err))
  // }, [])

  const analysisNotify = (type: NotificationsKey, id: string, productCode?: string): Lib.T.analysisNotifyRes => {
    switch (type) {
      case 'order':
        if (type === 'order') {
          return {
            href: `/orders/order-detail/${id}`,
            icon: 'cart',
          }
        } else {
          return { href: '#', icon: 'folks' }
        }

      case 'product':
        return {
          href: `/products/list?productCode=${productCode}&fromNotification=true`,
          icon: 'cart',
        }
      case 'pendingProducts':
        return {
          href: '/products/pending-products',
          icon: 'cart',
        }
      case 'problem':
        return {
          href: '/products/product-error',
          icon: 'cart',
        }
      case 'customer':
        return {
          href: '/customers/list',
          icon: 'cart',
        }
      default:
        return {
          href: '#',
          icon: 'folks',
        }
    }
  }

  const updateToRead = async (id: number) => {
    const service = new NotificationsService()
    setLoader(true)
    const { success, totalUnread } = await service.makeRead(id)
    if (!success) {
      return swal({
        text: 'something went wrong',
        icon: 'error',
      }).then(() => setLoader(false))
    }

    setData(perv => {
      const index = perv.findIndex(it => it.id === id)
      perv[index].read = true
      return perv
    })

    dispatch({ type: GET_UNREAD_NOTIFICATION_SUCCESS, payload: totalUnread })
    setLoader(false)
  }

  const deleteNotification = async (id: number) => {
    const requestBody = new NotificationsService()
    setLoader(true)
    await requestBody.delete(id).then(res => {
      if (res) {
        let isUnread = false

        const filtered = data.filter(item => {
          if (item.id === id && !item.read) {
            isUnread = true
          }
          return item.id !== id
        })
        setData(filtered)

        if (isUnread) {
          const minusUnreadNotifications = unReadNotifications - 1

          dispatch({ type: GET_UNREAD_NOTIFICATION_SUCCESS, payload: minusUnreadNotifications })
          setLoader(false)
        }
        setLoader(false)
      }
      setLoader(false)
    })
  }

  const handleShowMoreNotification = () => {
    if (notificationTotalData! / pagination.page <= pagination.limit) {
      return
    }
    setPagination(perval => {
      return { ...perval, page: perval.page + 1 }
    })
  }

  const timeHandler = (time: string) => {
    const receivedTime = moment(time).year() + moment(time).dayOfYear()
    const currentTime = moment().year() + moment().dayOfYear()
    const yesterday = moment(time).calendar()
    let returnTime = ''
    if (receivedTime === currentTime) {
      returnTime = moment(time).format(`Bugün, LT`)
    } else if (yesterday.substring(0, 3) === 'dün') {
      returnTime = moment(time).calendar()
    } else returnTime = moment(time).format('YYYY/MM/DD LT')

    return returnTime
  }
  useAsyncEffect(fetchNotifications, [pagination])

  return {
    val: { data, pagination, notificationTotalData, loader },
    on: { analysisNotify, deleteNotification, timeHandler, updateToRead, handleShowMoreNotification },
  }
}
