import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class LeaveService extends BaseService{
  endpoint = 'automation/leave'
  leaveTypeEndpoint = 'automation/leave-type'
  historyLeaveEndpoint = 'automation/leave/employee/officer/'

  constructor() {
    super();
  }

  async getList(filters?: Lib.T.Leave.GetListFilters): Promise<Lib.T.Leave.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200 || !data.leaves) {
        return { success: false, total: 0 }
      }
      return { success: true, leaves: data.leaves, total: data.total }
    }, config);
  }

  async getHistoryLeaveList(id:number, filters?: Lib.T.Leave.GetListFilters): Promise<Lib.T.Leave.GetHistoryLeaveResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.historyLeaveEndpoint).concat(id.toString()).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200 || !data.leaves) {
        return { success: false, total: 0 }
      }
      return { success: true, leaves: data.leaves, total: data.total }
    }, config);
  }

  async getLeaveTypeList(): Promise<Lib.T.Leave.GetListLeaveType> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.leaveTypeEndpoint),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200 || !data.leaveTypes) {
        return { success: false, total: 0 }
      }
      return { success: true, leaveTypes: data.leaveTypes, total: data.total }
    }, config);
  }

  async createOrEdit(args: Lib.T.Leave.CreateLeaveArgs, id?: number): Promise<Lib.T.Leave.CreateLeaveResult> {
    const config: IApiCallerConfig = {
      method: id ? 'PATCH' : 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      }
      return {
        success: true,
      }
    }, config);
  }

  async getOne(id: number): Promise<Lib.T.Leave.GetOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.user) {
        return { success: false }
      }

      return { success: true, user: data.user }
    }, config);
  }

  async getSellers(customerId: number): Promise<Lib.T.Leave.GetSellers> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/customer/' + customerId + '/findSellers'),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.sellers) {
        return { success: false, total: 0, sellers: [] }
      }

      return { success: true, total: data.total, sellers: data.sellers }
    }, config);
  }

  async getKarats(customerId: number): Promise<Lib.T.Leave.GetKarats> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/customer/' + customerId + '/findKarats'),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || !data.karats) {
        return { success: false, total: 0, karats: [] }
      }

      return { success: true, total: data.total, karats: data.karats }
    }, config);
  }

  async delete(id: number, deleteReason: string): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
      data: {deleteReason}
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return false
      }

      return true
    }, config);
  }

  async findKaratsForMultipleCustomers(
    filters?: Lib.T.Leave.findKaratsForMultipleCustomersArgType,
  ): Promise<Lib.T.Leave.findKaratsForMultipleCustomersResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('/customer/findKaratsForMultipleCustomers')
        .concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response

      if (status !== 200 || !data.karats) {
        return { success: false, total: 0 }
      }
      return { success: true, karats: data.karats, total: data.total }
    }, config);
  }
}
