import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import {
  OvertimeService,
  AutomationServiceIdsService,
  AutomationDepartmentService,
} from 'common/services'
import { useEffect, useState } from 'react'
import * as Lib from '.'
import { imageAddresser } from 'common/helpers/image.helper'
import { Moment } from 'moment'
import moment from 'moment'

export const useOvertime = () => {
  const { setLoader } = useLoader()

  const [showDepartmentModal, setShowDepartmentModal] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ name: '', departmentId: undefined, serviceId: undefined, date: '' })
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['', 'Ad', 'Servis', 'Departman', 'Tarih']
  const tableSizes = ['3%', 'unset', 'unset', 'unset', 'unset']

  const [departmentTableData, setDepartmentTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const departmentTableColumns = ['Departman', 'Kişi']
  const departmentTableSizes = ['unset', 'unset']

  const [serviceTableData, setServiceTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const serviceTableColumns = ['Servis', 'Kişi']
  const serviceTableSizes = ['unset', 'unset']

  const [serviceIdSelected, setServiceIdSelected] = useState<number | undefined>()
  const [serviceIds, setServiceIds] = useState([{ id: 0, title: '' }])
  const [departmentIdSelected, setDepartmentIdSelected] = useState<number | undefined>()
  const [departmentIds, setDepartmentIds] = useState([{ id: 0, title: '' }])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [date, setDate] = useState<string | undefined | Moment>('')

  const tableIdIndex = 6
  const departmentTableIdIndex = 3
  const serviceTableIdIndex = 3

  const handleModal = () => {
    setDepartmentIdSelected(undefined)
    setServiceIdSelected(undefined)
  }

  const handleCancelDepartmentModal = () => {
    setShowDepartmentModal(prev => !prev)
  }

  const handleCancelServiceModal = () => {
    setShowServiceModal(prev => !prev)
  }

  const addItemAtFirstIndex = (array: Lib.T.Item[], newItem: Lib.T.Item): Lib.T.Item[] => {
    return [newItem, ...array]
  }

  const getOvertime = async () => {
    const service = new OvertimeService()
    setLoader(true)
    const { success, overtimes, total } = await service.getList({
      limit: paginate.limit,
      name: query.name,
      departmentId: query.departmentId,
      serviceId: query.serviceId,
      date: query.date
    })
    setLoader(false)
    if (success && overtimes) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...overtimes.map(overtime => {
          return [
            <Lib.C.TableImage src={overtime.employee.avatar ? imageAddresser(overtime.employee.avatar.imageThumbnail, true) : '/images/png/avatar.png'} />,
            <div>{`${overtime?.employee?.firstName} ${overtime?.employee?.lastName}`}</div>,
            <div>{overtime?.employee?.service.name ?? '-'}</div>,
            <div>{overtime?.employee?.user?.userToRoles[0].department.name ?? '-'}</div>,
            <div>{moment(overtime?.date).format('DD/MM/YYYY')}</div>,
          ]
        }),
      ])
    }
  }

  const getDepartmentsPopup = async () => {
    const service = new OvertimeService()
    setLoader(true)
    const { success, overtimes } = await service.getList({
      responseType: 'department'
    })
    setLoader(false)
    if (success && overtimes) {
      setDepartmentTableData(prevData => [
        ...overtimes.map(overtime => {
          return [
            <div>{overtime?.department_name}</div>,
            <div>{overtime?.overtimes ?? '-'}</div>,
          ]
        }),
      ])
    }
  }

  const getServicePopup = async () => {
    const service = new OvertimeService()
    setLoader(true)
    const { success, overtimes } = await service.getList({
      responseType: 'service'
    })
    setLoader(false)
    if (success && overtimes) {
      setServiceTableData(prevData => [
        ...overtimes.map(overtime => {
          return [
            <div>{overtime?.service_name}</div>,
            <div>{overtime?.overtimes ?? '-'}</div>,
          ]
        }),
      ])
    }
  }

  const handleDepartmentsPopup = async () => {
    await getDepartmentsPopup()
    setShowDepartmentModal(true)
  }

  const handleServicePopup = async () => {
    await getServicePopup()
    setShowServiceModal(true)
  }

  const getServiceIds = async () => {
    const service = new AutomationServiceIdsService()
    setLoader(true)
    const { success, services } = await service.getList()
    setLoader(false)
    if (success && services) {
      const newItem: Lib.T.Item = { id: 0, title: '' }
      const serviceIdItems = services.map(item => ({
        id: item?.id,
        title: item?.name,
      }))
      setServiceIds(addItemAtFirstIndex(serviceIdItems, newItem))
    }
  }

  const getDepartments = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { data, success } = await service.getList('?isMain=true')
    setLoader(false)
    if (success && data) {
      setDepartmentIds(() => {
        return data.map(item => ({
          id: item?.id,
          title: item?.name,
        }))
      })
    }
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }


  useEffect(() => setTableData([]), [fetchAgain])
  useEffect(() => {
    ;(async () => {
      await getServiceIds()
      await getDepartments()
    })()
  }, [])

  useAsyncEffect(getOvertime, [paginate, fetchAgain])

  return {
    val: {
      serviceIdSelected,
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
      departmentIds,
      departmentIdSelected,
      date,
      showDepartmentModal,
      departmentTableIdIndex,
      departmentTableData,
      departmentTableColumns,
      departmentTableSizes,
      showServiceModal,
      serviceTableIdIndex,
      serviceTableData,
      serviceTableColumns,
      serviceTableSizes
    },
    set: {
      setServiceIdSelected,
      setQuery,
      setFetchAgain,
      setDepartmentIdSelected,
      setDate,
      setShowDepartmentModal,
      setShowServiceModal,
    },
    on: {
      handleModal,
      handleShowMore,
      handleCancelDepartmentModal,
      handleDepartmentsPopup,
      handleCancelServiceModal,
      handleServicePopup
    },
    get: {
      query,
      serviceIds,
      paginate,
      totalData,
    },
  }
}
