import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class AreaService extends BaseService{
  endpoint = 'area'
  constructor() {
    super();
  }

  async createArea(args: string): Promise<Lib.T.Options.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: { code: args },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 201) {
        return { success: false }
      }

      return {
        success: true,
      }
    }, config);
  }

  async edit(id: number, args: string): Promise<Lib.T.Options.CreateOrEditReturnTypeArea> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('area/').concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: { code: args },
    }
    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true }
    }, config);
  }

  async readArea(): Promise<Lib.T.Options.ReadReturnTypeArea> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response
      if (status !== 200) {
        return { success: false, total: 0 }
      }

      const data: Lib.T.Options.ReadDataTypeArea = response.data

      return {
        success: true,
        data,
        total: data.total,
      }
    }, config);
  }

  async readSingleArea(id: number): Promise<Lib.T.Options.ReadReturnTypeArea> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('area/')!.concat(id.toString()),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status } = response

      if (status !== 200) {
        return { success: false, total: 0 }
      }

      const data: Lib.T.Options.ReadDataTypeArea = response.data

      return {
        success: true,
        data,
      }
    }, config);
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`area/${id}`),
      headers: { authorization: this.accessToken },
    }

    return this.apiCallWithRefresh(async () => {
      const response = await ApiCaller(config)
      const { status, data } = response
      if (status !== 200 || data !== '') {
        return false
      }

      return true
    }, config);
  }
}
