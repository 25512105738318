import Axios, { AxiosRequestConfig } from 'axios'
import responseHandler from './axiosResponeHandler'
import { Storage } from 'common/helpers/storage'

export interface IApiCallerConfig extends AxiosRequestConfig {
  readonly customConfigs?: {
    readonly hidePopup?: boolean
  }
}

export const ApiCaller = (configs: IApiCallerConfig) => {
  const deviceId = Storage.get('deviceId')
  Axios.interceptors.request.use(
    async config => {
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )
  if (configs.url?.includes('auth/login') || configs.url?.includes('auth/verify-code')) {
    Axios.interceptors.response.use(
      response => {
        responseHandler(response, 'success', configs?.customConfigs?.hidePopup)
        return response
      },
      error => {
        responseHandler(error, 'error', configs?.customConfigs?.hidePopup)
        return error.response
      },
    )
  } else
  Axios.interceptors.response.use(
    response => {
      responseHandler(response, 'success', configs?.customConfigs?.hidePopup)
      return response
    },
  )

  return Axios.request({
    ...configs,
    headers: {...configs.headers,'device-id': deviceId, 'Accept-Language': 'tr'},
  })
}
