import { Modal } from 'antd'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { PermissionsType, RecineProjet, RolesTRType } from 'common/routes/types/roles.type'
import { AuthenticationService } from 'common/services'
import { RootState } from 'common/store/root-reducer'
import { SidebarOption, SidebarSubOption } from 'common/typing/sidebar-options'
import { getFirebaseToken } from 'firebase'
import { FC, Fragment, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SlideDown } from 'react-slidedown'
import * as Lib from '.'

export const SidebarSubItem: FC<SidebarSubOption> = ({ name, path, active }): JSX.Element => {
  return (
    <>
      <Lib.S.sidebarSubItems active={active}>
        <Link to={path}>{name}</Link>
      </Lib.S.sidebarSubItems>
    </>
  )
}

export const SidebarItem: FC<SidebarOption & { activeSubItem: string; group: any; isCollapsed: boolean }> = ({
  icon,
  name,
  path,
  subItems,
  active,
  activeSubItem,
  className,
  isCollapsed,
  group,
}): JSX.Element => {
  const [showSubItem, toggleSowSubItem] = useState<boolean>(active!)
  const { unReadNotifications, unApprovedProblems, unSeenMessages, socket } = useSelector((s: RootState) => s.globals)

  const [showModal, setShowModal] = useState(false)
  const handleModal = () => {
    setShowModal(perval => !perval)
  }

  const handleLogout = async () => {
    const token = await getFirebaseToken()
    await AuthenticationService.logOut(token ? token : undefined)
    socket.disconnect()
  }

  const haveBadge = useMemo(
    () => ({
      Bildirimler: unReadNotifications,
      Hatalar: unApprovedProblems,
      Mesajlar: unSeenMessages,
    }),
    [unReadNotifications, unApprovedProblems, unSeenMessages],
  )

  return (
    <>
      <Modal visible={showModal} onCancel={handleModal} footer={null} title={null} className="scrollWidthModal" closable>
        <Lib.S.ConfirmModalContainer>
          <p>Çıkış yapmak istediğinizden emin misiniz?</p>
          <div className="btns">
            <Button text="Vazgeç" mood="orang" className="submitButton" callback={() => handleModal()} />
            <Button mood="accent" className="submitButton" text="Onayla" callback={handleLogout} />
          </div>
        </Lib.S.ConfirmModalContainer>
      </Modal>

      <Lib.S.sidebarItem className={className} active={active}>
        <div className={`details ${active}`} onClick={() => toggleSowSubItem(!showSubItem)}>
          <DetailChild
            path={RecineProjet.includes(group) && path === '/orders/list' ? '/orders/tracking' : path}
            onClick={() => {
              name === 'Çıkış Yap' && handleModal()
            }}
          >
            <span className="icon">
              <Icon name={icon} color="white" size={isCollapsed ? 30 : 14} />
            </span>
            {isCollapsed ? null : (
              <div className="name">
                <p>{name}</p>
                {/* @ts-ignore */}
                {haveBadge[name] && haveBadge[name] > 0 ? <p className="circle">{haveBadge[name] < 99 ? haveBadge[name] : '+99'}</p> : null}
                {!showSubItem && subItems.length ? <Icon name='chevron_down' size={12} color="#fff" style={{marginTop: 5}} /> :
                  (subItems.length ? <Icon name='chevron_up' size={12} color="#fff" style={{marginTop: 5}} /> : '')
                }
              </div>
            )}
          </DetailChild>
        </div>
        <SlideDown className="subItems">
          {showSubItem &&
            subItems.length &&
            subItems.map((item, index) => {
              return item.newPermissions?.some(role=> group.includes(role)) ? (
                <Lib.C.SidebarSubItem {...item} key={item.name} active={activeSubItem === item.path && active} />
              ) : ( item.secondaryPermissions?.some(role=> group.includes(role)) ? (
                  <Lib.C.SidebarSubItem {...item} key={item.name} active={activeSubItem === item.path && active} />
                ) :
                <Fragment key={item.name} />
              )
            })}
        </SlideDown>
      </Lib.S.sidebarItem>
    </>
  )
}

const DetailChild: FC<Pick<SidebarOption, 'path' | 'onClick'>> = ({ children, path, onClick }): JSX.Element => {
  const { sidebarDisabled } = useSelector((state: RootState) => state.globals)

  const clickHandler = () => {
    if (onClick) {
      onClick()
    }
  }

  if (path === '#') {
    return <>{!sidebarDisabled ? <span onClick={clickHandler}>{children && children}</span> : null}</>
  } else {
    return <>{!sidebarDisabled ? <Link to={path}>{children && children}</Link> : null}</>
  }
}
