import styled from 'styled-components'
import * as Lib from '.'

const IHP = '6px' // INPUT_HORIZONTAL_PADDING
const IVPWI = '40px' // INPUT_VERTICAL_PADDING_WITH_ICON
const IVPNI = '15px' // INPUT_VERTICAL_PADDING_NO_ICON

export const inputContainer = styled.div<Lib.T.ContainerProps>`
  width: 100%;
  height: auto;
  float: left;

  .input::placeholder {
    color: #000;
  }

  > label {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;

    > span.label {
      float: left;
      width: 100%;
      padding: 7px 0;
      font-size: 14px;

      > span {
        color: var(--accent);
        font-size: 14px;
        margin: 0 0 0 4px;
      }
    }

    > span.icon {
      position: absolute;
      bottom: 2px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.password {
        right: 2px;
        cursor: pointer;
      }

      &.custom {
        left: 2px;
      }
    }

    > input {
      outline: none !important;
      border: 3px solid rgb(0 0 0 / 10%);
      float: left;
      width: 100%;
      height: 45px;
      border-radius: 7px;
      padding: ${({ padding }) => `${IHP} ${padding.right ? IVPWI : IVPNI} ${IHP} ${padding.left ? IVPWI : IVPNI}`};
      transition: all 150ms linear;

      &:focus {
        border-color: var(--accent);
      }

      &::placeholder {
        color: rgb(0 0 0 / 50%);
      }

      ${({ hideNumberInputArrows }) =>
        hideNumberInputArrows
          ? `
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type=number] {
          -moz-appearance: textfield;
        }
      `
          : null}
    }
  }
`
